import firebase from "firebase/app";
import "firebase/messaging";

const config = {
  apiKey: "AIzaSyDLIMJ5BToRe1egZLnJuOm5igqLAxmbtz0",
  authDomain: "heorderapp.firebaseapp.com",
  databaseURL: "https://heorderapp.firebaseio.com",
  projectId: "heorderapp",
  storageBucket: "heorderapp.appspot.com",
  messagingSenderId: "406024613648",
  appId: "1:406024613648:web:529c163f8c89dd0990ff95",
  measurementId: "G-6505EFWP3X",
};

// if(firebase.messaging.isSupported) {
//   firebase.initializeApp(config);
// } else {
//   console.log('firebase not supported')
// }

firebase.initializeApp(config);

export const messaging = firebase.messaging();

export const askForPermissionToReceiveNotification = async () => {
  try {
    await messaging.requestPermission();

    const token = await messaging.getToken({
      vapidKey:
        "BBp_OayFsfNSGTSWp6i9tXJqWg6voWLgaOp_4ogxnn_Z9SZmOKS0FgOIykTUzv9iZQ-umNKleM_-_rZGAlPjrcA",
    });
    return token;
  } catch (err) {}
};
