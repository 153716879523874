import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import { Redirect } from 'react-router-dom';

import { Creators } from '../../../services/redux/auth/actions';
import { getRefreshToken } from '../../../services/api/axiosDefaults';
import { getPhoneNumberFromLocalStore } from '../../../services/local-store';

const mapStateToProps = state => {
  return {
    isRefreshingToken: state.auth.isRefreshingToken,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    refreshToken: (refreshToken) => {
      dispatch(Creators.refreshTokenRequest(refreshToken));
    },
    launch: (phoneNumber) => {
      dispatch(Creators.launchRequest(phoneNumber))
    }
  };
};

const RefreshToken = (props) => {
    const refreshToken = getRefreshToken();
    useEffect(() => {
        props.refreshToken(refreshToken);
        // eslint-disable-next-line
    }, [])

    if (refreshToken) {
        return (
            <div className="load-more">
                <Spin size="large" tip="Refreshing token..." />
            </div>
        )
    } else {
      if(!getPhoneNumberFromLocalStore()) {
        return <Redirect to={{ pathname: "/" }} />
      } else {
        return <Redirect to={{pathname: '/login' }} />
      }
    }  
}

export default connect(mapStateToProps, mapDispatchToProps)(RefreshToken);
