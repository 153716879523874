import React, { useEffect } from 'react';
import { Spin } from 'antd';
import { connect } from 'react-redux';
import queryString from 'query-string';
import get from "lodash/get";

import { Creators } from '../../../services/redux/auth/actions'

const SyncPromotions = ({ getUserDetails, isLoading, ...props }) => {

    const syncPromotionsAndRedirect = async () => {
        const refresh = get(queryString.parse(props?.location?.search), "refresh")
        if(refresh === true || refresh === "true") {
            if ('caches' in window) {
                await caches.delete('endpoints-cache')
            }
        }
        window.location = "/dashboard"
    }

    useEffect(() => {
        getUserDetails(JSON.parse(localStorage.getItem("he-che-user")).id);
        // eslint-disable-next-line
    }, [])

    if (!isLoading) {
      syncPromotionsAndRedirect()
    }
    return (
        <div className="load-more">
            <Spin size="large" tip="Updating Prootion Products..." />
        </div>
    )
}

const mapStateToProps = state => {
    return {
        isLoading: state.auth.isFetching,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getUserDetails: (id) => {
            dispatch(Creators.getUserDetails(id))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SyncPromotions);