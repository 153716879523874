import { createActions } from "reduxsauce";

export const { Types, Creators } = createActions(
	{
		fetchUsers: ["query"],
		fetchUsersSuccess: ["responseData"],
		fetchUsersFailure: ["error"],

    updateUser: ["id","payload"],
		updateUserSuccess: ["responseData"],
		updateUserFailure: ["error"],

		resetUser: null
	},
	{}
);