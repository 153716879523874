import { call, put, takeLatest, takeLeading } from 'redux-saga/effects';
import { Creators, Types } from '../../redux/products/actions';
import {
  productList as productListApi,
  productCategory as productCategoryApi,
  getAllProductList as getAllProductListApi,
  checkPrices as checkProductPricesApi
} from '../../api/products';
import { filterProducts as filterProductsLocally } from '../../local-store'
import { handleError } from '../../../util/helpers/handleError';
import { message } from 'antd';
import { rollbar } from '../../../util/configs';

export function* getProducts(actions) {
  try {
    const { query } = actions;
    const urlSearchParams = new URLSearchParams(query)
    const categoryId = parseInt(urlSearchParams.get('categ_id__id')) || null
    const searchTerm = urlSearchParams.get('search') || null

    if (categoryId || searchTerm || query.includes('search=')) {
      const res = yield call(filterProductsLocally, categoryId, searchTerm)
      yield put(Creators.getProductListSuccess(res))
    } else {
      const response = yield call(productListApi, query);
      yield put(Creators.getProductListSuccess(response.data));
    }
  } catch (e) {
    rollbar.error(`Fetch products error ${e}`)
    message.error(e.message)
    yield put(Creators.getProductListFailure(handleError(e)));
  }
}

export function* syncProducts(actions) {
  const { query } = actions
  try {
    yield call(getAllProductListApi, query)
    yield put(Creators.syncProductsSuccess())
    message.success('Products synced! / Produits synchronisés !')
  } catch (error) {
    rollbar.error(`Sync products error ${error}`)
    yield put(Creators.syncProductsFailure())
  }

}

export function refreshProductListCache(action) {
  if ('caches' in window) {
    caches.delete('endpoints-cache')
    caches.delete('images-cache')
    caches.keys().then(names => {
      names.forEach(name => {
        caches.delete(name)
      })
    })
    window.location.reload(true)
  } else {
    console.log('Browser does not support cache storage!')
  }
}



export function* getProductCategory(actions) {
  try {
    const response = yield call(productCategoryApi);
    yield put(Creators.getProductCategorySuccess(response.data));
  } catch (e) {
    rollbar.error(`Fetch product category error ${e}`)
    yield put(Creators.getProductCategoryFailure(handleError(e)));
  }
}

export function* checkProductListPrice(action) {
  const { responseData } = action
  console.log('check product list action', responseData)
  try {
    const payload = responseData?.results.map(it => ({
      id: it?.id,
      quantity: 1
    }))
    const response = yield call(checkProductPricesApi, {order_products: payload})
    yield put(Creators.checkProductListPriceSuccess(response?.data))
  } catch(e) {
    yield put(Creators.checkProductListPriceFailure("Failed to check product prices!"))
    message.error("Failed to check Product prices")
  }
}

export function* watchGetProducts() {
  yield takeLeading(Types.GET_PRODUCT_LIST_REQUEST, getProducts);
}

export function* watchGetProductCategory() {
  yield takeLeading(Types.GET_PRODUCT_CATEGORY_REQUEST, getProductCategory);
}

export function* watchSyncProducts(actions) {
  yield takeLeading(Types.SYNC_PRODUCTS, syncProducts)
}

export function* watchRefreshProductListCache() {
  yield takeLeading(Types.REFRESH_PRODUCT_LIST_CACHE, refreshProductListCache)
}

export function* watchCheckProductPrices() {
  yield takeLeading(Types.CHECK_PRODUCT_LIST_PRICE, checkProductListPrice)
}


export function* watchGetProductsSuccess() {
  yield takeLatest(Types.GET_PRODUCT_LIST_SUCCESS, checkProductListPrice)
}

