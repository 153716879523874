import { call, put, takeLatest } from "redux-saga/effects";
import { Creators, Types } from "../../redux/orders/actions";
import {
	fetchOrders as fetchOrdersApi,
	fetchOrderDetail as fetchOrderDetailApi
} from "../../api/orders";
import { handleError } from "../../../util/helpers/handleError";
import { rollbar } from "../../../util/configs";

const extractNextPage = (url) => {
	let nextPage = null;
	const splits = url.split("?");
	const params = splits[1].split("&");
	params.forEach((param) => {
		const paramKeys = param.split("=");
		if (paramKeys[0] === "page") nextPage = paramKeys[1];
	});
	return nextPage;
};

export function* fetchOrders(actions) {
	try {
		const { query } = actions;
		const response = yield call(fetchOrdersApi, query);
		const resp = response && response.data;
		let hasMore = false;
		let nextPage = null;
		if (resp && resp.next) {
			hasMore = true;
			//extract next page from url...
			nextPage = extractNextPage(resp && resp.next);
		}
		yield put(
			Creators.fetchOrdersSuccess(
				resp && resp.results,
				resp && resp.count,
				hasMore,
				nextPage
			)
		);
	} catch (error) {
		rollbar.error(`Fetch orders error ${error}`)
		// let errorsFound = error.response && error.response.data;
		yield put(Creators.fetchOrdersFailure(error.response && error.response.data));
	}
}

export function* fetchOrderDetails(actions) {
	try {
		const { id } = actions;
		const response = yield call(fetchOrderDetailApi, id);
		yield put(Creators.fetchOrderDetailsSuccess(response.data)
		);
	} catch (error) {
		rollbar.error(`Fetch order details error ${error}`)
		yield put(Creators.fetchOrderDetailsFailure(handleError(error)));
	}
}

export function* watchFetchOrders() {
	yield takeLatest(Types.FETCH_ORDERS, fetchOrders);
}

export function* watchFetchOrderDetails() {
	yield takeLatest(Types.FETCH_ORDER_DETAILS, fetchOrderDetails);
}
