import { createReducer } from "reduxsauce";
import Types from "./actionTypes";

export const INITIAL_STATE = {
  errors: null,
  isloading: false,
  orders: [],
  totalCount: 0,
  hasMore: false,
  nextPage: null,

  fetchingOrderDetails: false,
  details: null,
  orderDetailsError: null,
};

export const fetchOrders = (state = INITIAL_STATE, action) => {
  return { ...state, errors: null, isloading: true };
};

export const fetchOrdersSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    orders: [...state.orders, ...action.results],
    totalCount: action.totalCount,
    hasMore: action.hasMore,
    nextPage: action.nextPage,
    isloading: false,
  };
};

export const fetchOrdersFailure = (state = INITIAL_STATE, action) => {
  return { ...state, isloading: false, errors: action.error };
};

export const resetPagination = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    errors: null,
    isloading: false,
    orders: [],
    totalCount: 0,
    hasMore: false,
    nextPage: null,
  };
};

export const fetchOrderDetails = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    details: null,
    orderDetailsError: null,
    fetchingOrderDetails: true,
  };
};

export const fetchOrderDetailsSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    details: action.responseData,
    fetchingOrderDetails: false,
  };
};

export const fetchOrderDetailsFailure = (state = INITIAL_STATE, action) => {
  return { ...state, fetchingOrderDetails: false, orderDetailsError: action.error };
};

export const HANDLERS = {
  [Types.FETCH_ORDERS]: fetchOrders,
  [Types.FETCH_ORDERS_SUCCESS]: fetchOrdersSuccess,
  [Types.FETCH_ORDERS_FAILURE]: fetchOrdersFailure,

  [Types.FETCH_ORDER_DETAILS]: fetchOrderDetails,
  [Types.FETCH_ORDER_DETAILS_SUCCESS]: fetchOrderDetailsSuccess,
  [Types.FETCH_ORDER_DETAILS_FAILURE]: fetchOrderDetailsFailure,

  [Types.RESET_PAGINATION]: resetPagination,
};

export default createReducer(INITIAL_STATE, HANDLERS);
