import React from 'react';
import { Route } from 'react-router-dom';
import decode from 'jwt-decode';

import { getToken } from '../../services/api/axiosDefaults';
import { Redirect } from "react-router-dom"

// import { excludeFromMaintenanceScreen } from './commonHelper';
// import { Redirect } from 'react-router-dom';

export const checkAuth = () => {
  let token = getToken();
  if (!token) {
    return false;
  }
  try {
    const { exp } = decode(token);
    if (exp < new Date().getTime() / 1000) {
      return false;
    }
  } catch (e) {
    return false;
  }
  return true;
};


const RedirectToPage = () => {
  if(localStorage.getItem("phone_number")) {
    return <Redirect to="/launch-refresh" />
  } else {
    return <Redirect to="/refresh"/>
  }
}



const PrivateRoute = ({component: Component, ...rest}) => {
  // if(!excludeFromMaintenanceScreen(localStorage.getItem("phone_number"))) {
  //   return <Redirect to="/maintenance" />
  // }
    return (
      <Route
        {...rest}
        render={(props) => checkAuth()
          ? <Component {...props} />
          : RedirectToPage()}
      />
    )
}

export default PrivateRoute;
