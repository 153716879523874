import { createTypes } from "reduxsauce";

export default createTypes(
	`
  ADD_ITEM

  INCREMENT_ITEM

  ADD_FREE_PRODUCTS

  DECREMENT_ITEM

  REMOVE_ITEM

  SET_CURRENCY

  SEND_ORDER
  SEND_ORDER_SUCCESS
  SEND_ORDER_FAILURE

  RESET_ORDER

  RESET_ORDER_ERROR

  SET_ORDERING_FOR
  SET_ORDERING_FOR_SUCCESS
  SET_ORDERING_FOR_FAILURE

  CHECK_CART_PRODUCT_PRICE
  CHECK_CART_PRODUCT_PRICE_SUCCESS
  CHECK_CART_PRODUCT_PRICE_FAILURE

  CLEAN_UNACCEPTED_PRODUCTS_FROM_CART

  UPDATE_CHE

`,
	{}
);
