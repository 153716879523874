import { createReducer } from "reduxsauce";
import Types from "./actionTypes";

export const INITIAL_STATE = {
  data: null,
  localPhoneNumber: null,
  loading: false
};

export const getPhoneNumberDetails = (state = INITIAL_STATE, action) => {
  return { ...state, loading: true, error: null, data: null };
};


export const getPhoneNumberDetailsSuccess = (state = INITIAL_STATE, action) => {
  const { responseData } = action
  return { ...state, loading: false, error: null, data: responseData };
};

export const getPhoneNumberDetailsFailure = (state = INITIAL_STATE, action) => {
  const { error } = action
  return { ...state, loading: false, error: error, data: null };
};

export const resetPhoneNumberDetails = (state = INITIAL_STATE, action) => {
  return { ...state, loading: false, error: null, data: null };
};



export const HANDLERS = {
  [Types.GET_PHONE_NUMBER_DETAILS]: getPhoneNumberDetails,
  [Types.GET_PHONE_NUMBER_DETAILS_SUCCESS]: getPhoneNumberDetailsSuccess,
  [Types.GET_PHONE_NUMBER_DETAILS_FAILURE]: getPhoneNumberDetailsFailure,
  [Types.RESET_PHONE_NUMBER_DETAILS]: resetPhoneNumberDetails,
};

export default createReducer(INITIAL_STATE, HANDLERS);
