import { IMGAE_PREFIX } from "../../../util/configs";
import { apiRequest } from "../apiRequest";
import {
	PRODUCT_LISTS_ENDPOINT,
	PRODUCT_CATEGORY_ENDPOINT
} from '../urls';

export const PRODUCT_SALE_OK = true;

export const productList = (query) => { 
	const URL = query ? `${PRODUCT_LISTS_ENDPOINT}${query}` : PRODUCT_LISTS_ENDPOINT;
	return apiRequest('GET', URL);
}

export const productCategory = () => {
	return apiRequest('GET', PRODUCT_CATEGORY_ENDPOINT)
}

export const getAllProductList = async (query) => {
	try {
		let URL = query ? `${PRODUCT_LISTS_ENDPOINT}${query}` : PRODUCT_LISTS_ENDPOINT;
		const response = await apiRequest("GET", URL)
		const data = response && response.data
		data.results.forEach(async (item)=> {
			if(item.image) {
				//await fetch(item.image)
				let img = new Image()
				img.src = item.image + '?' + IMGAE_PREFIX
				//fetch(item.image)
				//(new Image()).src = await item.image
			}
		})
		if(data.next) {
			let nextQuery = data.next.split('?')[1]
			await getAllProductList(`?${nextQuery}`)
		}
	} catch (error) {
		
	}
}

export const checkPrices = (payload) => {
  return apiRequest("POST", "/products/check_prices/", payload)
}


