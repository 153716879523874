/* Base Urls */
export const BASE_URL = process.env.REACT_APP_BASE_URL;

/* Auth endpoints */
export const ACTIVATE_LOGIN_ENDPOINT = "/user/auth/activate/";
export const SET_PIN_ENDPOINT = "/user/auth/activate/setpin/";
export const LOGIN_ENDPOINT = "/user/auth/login/";
export const LAUNCH_ENDPOINT = "/user/auth/launch/";
export const CHANGE_PIN_ENDPOINT = "/user/auth/change_pin/";
export const USER_PROFILE_ENDPOINT = "/user/auth/profile/";
export const SAVE_DEVICE_TOKEN = "/user/device/";
export const REFRESH_TOKEN = "/user/auth/refresh"
export const ACCOUNTING_ENDPOINT = "/accounting"
//export const SAVE_DEFAULT_PHONE_NUMBER = "/user/"

/* PRODUCTS ENDPOINT */
export const PRODUCT_LISTS_ENDPOINT = "/products/";
export const PRODUCT_CATEGORY_ENDPOINT = "/products/category/?page=1&limit=200";

/* ORDERS ENDPOINT */
export const ORDERS_ENDPOINT = "/orders/";

/* PAYMENTS ENDPOINT */
export const PAYMENTS_ENDPOINT = "/payments/";
