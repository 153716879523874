import "react-app-polyfill/ie11";
import "react-app-polyfill/ie9";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "./store";
import { Provider as RollbarProvider, ErrorBoundary as RollbarErrorBoundary } from '@rollbar/react'
import "./styles/main.less";
import { askForPermissionToReceiveNotification } from "./firebase";
import { rollbarConfig } from "./util/configs";

const ErrorDisplay = ({ error, resetError }) => ( // <-- props passed to fallbackUI component
  <div>
    <h1>A following error has occured:</h1>
    <p>{error.toString()}</p>
  </div>
);

ReactDOM.render(
	<RollbarProvider config={rollbarConfig}>
		<RollbarErrorBoundary fallbackUI={ErrorDisplay}>
			<Provider store={store}>
				<PersistGate loading={null} persistor={persistor}>
					<App />
				</PersistGate>
			</Provider>
		</RollbarErrorBoundary>
	</RollbarProvider>,
	document.getElementById("root")
);

askForPermissionToReceiveNotification();
