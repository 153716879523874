import React, { useEffect } from 'react';
import { Spin } from 'antd';
import { connect } from 'react-redux';

import { Creators } from '../../../services/redux/auth/actions'
import { clearEndpointCache } from '../../../services/local-store';

const SyncPayment = ({ getUserDetails, isLoading }) => {
    useEffect(() => {
        clearEndpointCache()
        getUserDetails(JSON.parse(localStorage.getItem("he-che-user")).id);
        // eslint-disable-next-line
    }, [])

    if (!isLoading) {
        window.location = "/dashboard"
    }
    return (
        <div className="load-more">
            <Spin size="large" tip="Updating Payment Methods..." />
        </div>
    )
}

const mapStateToProps = state => {
    return {
        isLoading: state.auth.isFetching,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getUserDetails: (id) => {
            dispatch(Creators.getUserDetails(id))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SyncPayment);