import { call, put, takeLatest, takeLeading } from 'redux-saga/effects';
import { Creators, Types } from '../../redux/users/actions';
import {
	fetchUsers as fetchUsersApi,
  updateUser as updateUserApi,
} from '../../api/user';
import { handleError } from '../../../util/helpers/handleError';
import { message } from 'antd';

export function* fetchUsers(actions) {
	try {
		const { query } = actions;
		const response = yield call(fetchUsersApi, query)
    yield put(Creators.fetchUsersSuccess(response?.data?.results))
	} catch (e) {
		message.error(e.message)
		yield put(Creators.fetchUsersFailure(handleError(e)));
	}
}

export function* updateUser(actions) {
	try {
		const { id, payload } = actions;
		const response = yield call(updateUserApi, id, payload)
    yield put(Creators.updateUserSuccess(response?.data))
	} catch (e) {
		message.error(e.message)
		yield put(Creators.updateUserFailure(handleError(e)));
	}
}

export function* watchFetchUsers(actions) {
	yield takeLatest(Types.FETCH_USERS, fetchUsers)
}

export function* watchUpdateUser(actions) {
  yield takeLeading(Types.UPDATE_USER, updateUser)
}


