import { apiRequest } from "../apiRequest";
import { PAYMENTS_ENDPOINT } from "../urls";

export const makePayment = (data) => {
  return apiRequest("POST", PAYMENTS_ENDPOINT, data);
};

export const makeTestPayment = async (data) => {
	const result = {
		id: 77181919,
		order_id: data?.order_id || 12293,
		amount: data.amount,
		phone_number: data.phone_number,
		payment_method: 'test',
		status: 'success'
	}
	return new Promise((response, reject) => {
		setTimeout(() => response(result), 5000)
	})
}

export const checkTestOrderPaymentStatus = async (data) => {
  console.log('-------- IN TEST ORDER PAYMENT STATUS CHECH API -------')
	const result = {
    data: {
        order: {
            id: 40732,
            name: "S28886",
            display_name: "S28886",
            invoice_ids: [
              "676109"
            ],
            state: "done",
            route_id: [
                523,
                "KUM - 2023-12-12"
            ]
        },
        invoice: [
          {
            id: "676109",
            payment_state: "in-payment"
          }
        ],
        payment: {
          transaction_id: "99033803809",
          amount: "500",
          id: "66846",
          status: "successful"
        },
        route: {
            delivery_start: "2023-12-12",
            delivery_end: "2023-12-12",
            id: 523
        }
    }
}
	return new Promise((response, reject) => {
		setTimeout(() => response(result), 2000)
	})
}

export const verifyNumber = async(phone_number) => {
	return apiRequest("GET", `/telnyx/${phone_number}?salt=${Math.floor(Date.now() / 1000)}`)
}


export const checkTransactionStatus = (paymentId) => {
  return apiRequest(
    "GET",
    `/payments/${paymentId}/transaction_status`,
  )
}

export const orderPaymentStatusCheck = (payload) => {
  return apiRequest(
    "POST",
    `/orders/order_payment_status/`,
    payload
  )
}
