import { createActions } from "reduxsauce";

export const { Types, Creators } = createActions(
  {
    makePaymentRequest: ["data"],
    makePaymentSuccess: ["responseData"],
    makePaymentFailure: ["error"],

    makeTestPaymentRequest: ["data"],
    makeTestPaymentSuccess: ["responseData"],
    makeTestPaymentFailure: ["error"],
    
    waitForPayment: [],
    setPaymentProcessing: ["telco"],
    setPaymentTimeup: ["status"],
    resetPayment: null,

    setFakeMomoPaymentModalVisible: ["status"],
    setFakeMomoPaymentPin: ["pin"],

    makeFakePayment: ["data"],
    makeFakePaymentSuccess: ["responseData"],
    makeFakePaymentFailure: ["error"],

    cancelFakePayment: null,
    cancelFakePaymentSuccess: ["responseData"],
    cancelFakePaymentFailure: ["error"],

    checkTransactionStatus: ["paymentId"],
    checkTransactionStatusSuccess: ["responseData"],
    checkTransactionStatusFailure: ["error"],

    checkOrderPaymentStatus: ["order_or_invoice","payload"],
    checkOrderPaymentStatusSuccess: ["responseData"],
    checkOrderPaymentStatusFailure: ["error"],

    completePayment: []
  },
  {}
);
