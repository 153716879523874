import { momoChannels } from "../configs";

export function numberWithCommas(str) {
	if (str) {
		var parts = str.toString().split(".");
		parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		return parts.join(".");
	}

	return str;
}

export const getPaymentMethods = () => {
	let paymentMethods = [];
	let user = JSON.parse(localStorage.getItem("he-che-user"));
	let payment_methods = user && user.country && user.country.payment_methods;
	if (payment_methods.length > 0)
		payment_methods.forEach((item) => paymentMethods.push(item.name));
	return paymentMethods;
};

export const getUserProfileFromStorage = () => {
	const userProfile = localStorage.getItem("he-che-user");
	if(userProfile === 'undefined' || userProfile === null) {
		return null;
	} else {
		return JSON.parse(localStorage.getItem("he-che-user"))
	}
}

export const hasMomoPaymentConfig = () => {
	const paymentMethods = getPaymentMethods()
	const found = paymentMethods.some(pm => momoChannels.includes(pm))
	return found;
}

export const prefixPhoneNumber = (phoneNumber, prefix) => {
  if(phoneNumber.indexOf("+") === 0) {
    return phoneNumber
  } else if(prefix === "+257") {
    return `${prefix}${phoneNumber}`
  } else {
    const phoneNumberArr = phoneNumber.split("")
    phoneNumberArr[0] = prefix;
    const formatedNumber = phoneNumberArr.join("")
    return formatedNumber;
  }
}

export const getPhoneNumberWithoutPrefix = (phoneNumber, phoneNumberPrefix) => {
  let formatedNumber = phoneNumber || '';
  if(phoneNumber?.startsWith('+257')) {
    formatedNumber = phoneNumber?.replace('+257', '')
  } else if(phoneNumber?.startsWith("+") ) {
    formatedNumber = phoneNumber?.replace(/^.{4}/g, '0')
  }
  return formatedNumber
}

export const getTelcoNameFromPhoneNumber = (responseData) => {
	let telcoName = responseData?.carrier?.name || null
	let phoneNumber = responseData?.phone_number;
	let serviceProvider = null;
	// if(!telcoName) {
	// 	return "Unknown Provider"
	// }
	
	if(telcoName) {
		const telco = telcoName.toLowerCase();
		if(telco.includes("mtn")) {
			serviceProvider = "momo"
		} else if(telco.includes("airtel")) {
			serviceProvider = "airtel"
		} else if(telco.includes("mpesa")) {
			serviceProvider = "mpesa"
		} else if(telco.includes("ihela")) {
			serviceProvider = "ihela"
		} else if(telco.includes("safaricom")) {
			serviceProvider = "mpesa"
		} else if(telco.includes("burundi")) {
			serviceProvider = "ihela"
		} else if(telco.includes("uganda telecom limited")) {
			serviceProvider = "mpesa"
		} else if(telco.includes("suretelcom")) {
			return "airtel"
		} else if(telco.includes("econet leo") || telco.includes("econet")) {
			return "ihela"
		} else if(telco.includes("k2 telecom ltd")) {
      return "airtel"
    } else {
			serviceProvider = null
		}
	} else {
		if(phoneNumber.indexOf("+256708") === 0 || phoneNumber.indexOf("+256709") === 0) {
			serviceProvider = "airtel"
		} else if(phoneNumber.includes("+25676")) {
			serviceProvider = "momo"
		} else if(phoneNumber.startsWith("+25674")) {
			serviceProvider = "airtel" 
		} else {
			serviceProvider = null;
		}
	}
	return serviceProvider;
}

export const verifyAirtelNumbers = (number) => { 
	return number.indexOf("708") === 4;
}

export const prettifyMomoService = (service) => {
	if(!service) {
		return "Unknown Provider"
	} else if(service === "momo") {
		return "MTN"
	} else {
		return service.toUpperCase()
	}
}

export const displayServiceProvider = (service) => {
	switch(service) {
		case "airtel":
			return "Airtel"
		case "mpesa":
			return "M-PESA"
		case "momo":
			return "MTN"
		case "ihela":
			return "EcoCash"
		case "vodafone":
			return "Vodafone"
		case "not-supported":
			return "Not Supported"
		case "not-configured":
			return "Service Provider Not Allowed"
		default:
			return "Unknown"
	}
}

export const isServiceProviderSupported = (serviceProvider) => {
	return ["airtel", "momo", "mpesa", "ihela"].includes(serviceProvider)
}

export const isServiceProviderConfiguredForCountry = (serviceProvider) => {
	return getPaymentMethods().includes(serviceProvider) && ["airtel", "momo", "mpesa", "ihela"].includes(serviceProvider);
}


const PROTOCOL = window.location.protocol;
const PORT = window.location.port;
const HOSTNAME = window.location.hostname;
export const CURRENT_URL = PORT
  ? `${PROTOCOL}//${HOSTNAME}:${PORT}`
  : `${PROTOCOL}//${HOSTNAME}`;

