import { createTypes } from "reduxsauce";

export default createTypes(
	`
  GET_PRODUCT_LIST_REQUEST
  GET_PRODUCT_LIST_SUCCESS
  GET_PRODUCT_LIST_FAILURE

  GET_PRODUCT_CATEGORY_REQUEST
  GET_PRODUCT_CATEGORY_SUCCESS
  GET_PRODUCT_CATEGORY_FAILURE

  CHECK_PRODUCT_PRICES
  CHECK_PRODUCT_PRICES_SUCCESS
  CHECK_PRODUCT_PRICES_FAILURE

  CHECK_PRODUCT_LIST_PRICE
  CHECK_PRODUCT_LIST_PRICE_SUCCESS
  CHECK_PRODUCT_LIST_PRICE_FAILURE

  SYNC_PRODUCTS
  SYNC_PRODUCTS_SUCCESS
  SYNC_PRODUCTS_FAILURE

  RESET_CHECK_PRODUCT_PRICES
  RESET_CHECK_PRODUCT_LIST_PRICE

  REFRESH
  CLEAN_PRODUCTS

  REFRESH_PRODUCT_LIST_CACHE

`,
	{}
);
