import { createActions } from "reduxsauce";

export const { Types, Creators } = createActions(
	{
		fetchOrders: ["query"],
		fetchOrdersSuccess: ["results", "totalCount", "hasMore", "nextPage"],
		fetchOrdersFailure: ["error"],

		fetchOrderDetails: ["id"],
		fetchOrderDetailsSuccess: ["responseData"],
		fetchOrderDetailsFailure: ["error"],

		resetPagination: null,
	},
	{}
);
