function initializeAddToHomeScreen() {
  if ('onbeforeinstallprompt' in window) {
    window.addEventListener('beforeinstallprompt', (e) => console.log(""));

  }
  if ('onappinstalled' in window) {
    window.addEventListener('appinstalled', function (evt) {
      console.log('App installed');
    });
  }
  
  if ('serviceWorker' in navigator) {
    let manifestElement = document.querySelector('[rel=\'manifest\']');
    if (!manifestElement) {
      console.log('no manifest file');
    }
  }

}

export default initializeAddToHomeScreen;
