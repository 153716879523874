import { createTypes } from "reduxsauce";

export default createTypes(
	`
  FETCH_ORDERS
  FETCH_ORDERS_SUCCESS
  FETCH_ORDERS_FAILURE

  FETCH_ORDER_DETAILS
  FETCH_ORDER_DETAILS_SUCCESS
  FETCH_ORDER_DETAILS_FAILURE

  RESET_PAGINATION

`,
	{}
);
