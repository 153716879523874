const clearCache = () => {
  if ("caches" in window) {
    const cacheNames = ["endpoints-cache"];
    // caches.keys().then((names) => {
    //   // Delete all the cache files
    //   console.log("cache names are ", names);
    //   names.forEach((name) => {
    //     caches.delete(name);
    //   });
    // });

    cacheNames.forEach((name) => caches.delete(name));

    // // Makes sure the page reloads. Changes are only visible after you refresh.
    // window.location.reload(true);
  }
};

export default clearCache;
