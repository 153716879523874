import { createReducer } from "reduxsauce";
import Types from "./actionTypes";

export const INITIAL_STATE = {
  isMakingPayment: false,
  payment: null,
  paymentSuccess: false,
  paymentProcessing: localStorage.getItem("he-payment-processing") && JSON.parse(localStorage.getItem("he-payment-processing") || false),
  paymentTimeup: false,
  telco: localStorage.getItem("he-payment-processing-telco") || null,
  error: null,
  amount: 100,
  fakeMomoPaymentModalVisible: false,
  fakeMomoPaymentPin: "",
  checkingPaymentStatus: false,
  checkPaymentStatusSuccess: false,
  checkingOrderPaymentStatus: false,
  orderPaymentStatusCheckSuccess: false,
  paymentStatusData: null
};

export const makePaymentRequest = (state = INITIAL_STATE, action) => {
  const { payment_method, amount } = action.data
  window.localStorage.setItem("he-payment-processing", false)
  window.localStorage.setItem("he-payment-processing-telco", payment_method)
  return { ...state, 
    payment: null,
    isMakingPayment: true, 
    error: null, 
    paymentProcessing: false, 
    paymentTimeup: false,
    paymentSuccess: false,
    telco: payment_method,
    amount: amount
  };
};

export const makePaymentSuccess = (state = INITIAL_STATE, action) => {
  window.localStorage.setItem("he-payment-processing", false)
  return { ...state,
    payment: action.responseData,
    paymentSuccess: true,
    paymentTimeup: false,
    error: null,
  };
};


export const makePaymentFailure = (state = INITIAL_STATE, action) => {
  window.localStorage.setItem("he-payment-processing", false)
  return { ...state,
    error: action.error,
    paymentSuccess: false,
    paymentTimeup: false,
    paymentProcessing: false,
    fakeMomoPaymentModalVisible: false,
    isMakingPayment: false,
    amount: null
  };
};


export const makeTestPaymentRequest = (state = INITIAL_STATE, action) => {
  const { payment_method, amount } = action.data
  window.localStorage.setItem("he-payment-processing", false)
  window.localStorage.setItem("he-payment-processing-telco", payment_method)
  return { ...state, 
    payment: null,
    isMakingPayment: true, 
    error: null, 
    paymentProcessing: false, 
    paymentTimeup: false,
    paymentSuccess: false,
    telco: payment_method,
    amount: amount
  };
};

export const makeTestPaymentSuccess = (state = INITIAL_STATE, action) => {
  window.localStorage.setItem("he-payment-processing", false)
  return { ...state,
    payment: action.responseData,
    paymentSuccess: true,
    paymentTimeup: false,
    error: null,
  };
};


export const makeTestPaymentFailure = (state = INITIAL_STATE, action) => {
  window.localStorage.setItem("he-payment-processing", false)
  return { ...state,
    error: action.error, 
    paymentSuccess: false,
    paymentTimeup: false,
    amount: null
  };
};


export const completePayment = (state = INITIAL_STATE, action) => {
  window.localStorage.setItem("he-payment-processing", false)
  window.localStorage.setItem("he-payment-processing-telco", null)
  return { ...state,
    isMakingPayment: false,
    paymentSuccess: false,
    error: null,
    paymentTimeup: false,
    paymentProcessing: false,
    telco: null,
  };
};

export const updatePaymentStatus = (state = INITIAL_STATE, action) => {
  const { paymentProcessing } = action
  return {...state, paymentProcessing: paymentProcessing}
}

export const setPaymentProcessing = (state = INITIAL_STATE, action) => {
  window.localStorage.setItem("he-payment-processing", true)
  return {...state, paymentProcessing: true}
}

export const waitForPayment = (state = INITIAL_STATE, action) => {
  window.localStorage.setItem("he-payment-processing", false)
  return {...state, paymentTimeup: false, paymentProcessing: false}
}

export const setPaymentTimeup = (state = INITIAL_STATE, action) => {
  window.localStorage.setItem("he-payment-processing", false)
  window.localStorage.setItem("he-payment-processing-telco", null)
  return {...state, paymentTimeup: true, paymentProcessing: false}
}

export const resetPayment = (state = INITIAL_STATE, action) => {
  window.localStorage.setItem("he-payment-processing", false)
  window.localStorage.setItem("he-payment-processing-telco", null)
  return {
    ...state, 
    isMakingPayment: false, 
    error: null, 
    paymentTimeup: false, 
    paymentSuccess: false, 
    fakeMomoPaymentModalVisible: false,
    checkingOrderPaymentStatus: false,
    checkOrderPaymentSuccess: false
  }
}

export const setFakeMomoPaymentModalVisible = (state = INITIAL_STATE, action) => {
  return {...state, fakeMomoPaymentModalVisible: action.status}
}

export const setFakeMomoPaymentPin = (state = INITIAL_STATE, action) => {
  return {...state, fakeMomoPaymentPin: action?.pin}
}


export const makeFakePayment = (state = INITIAL_STATE, action) => {
  return {...state, fakeMomoPaymentModalVisible: true}
}

export const makeFakePaymentSuccess = (state = INITIAL_STATE, action) => {
  return {...state, fakeMomoPaymentModalVisible: false}
}

export const makeFakePaymentFailure = (state = INITIAL_STATE, action) => {
  return {...state, fakeMomoPaymentModalVisible: false}
}

export const cancelFakePayment = (state = INITIAL_STATE, action) => {
  return {
    ...state, 
    error: action.error,
    fakeMomoPaymentModalVisible: false,
  }
}

export const cancelFakePaymentSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state, 
    error: action.error,
    paymentSuccess: false,
    paymentTimeup: false,
    paymentProcessing: false,
    fakeMomoPaymentModalVisible: false,
    isMakingPayment: false,
    amount: null
  }
}

export const cancelFakePaymentFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state, 
    error: action.error,
    paymentSuccess: false,
    paymentTimeup: false,
    paymentProcessing: false,
    fakeMomoPaymentModalVisible: false,
    isMakingPayment: false,
    amount: null
  }
}

export const checkTransactionStatus = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    checkingPaymentStatus: true,
    checkPaymentStatusSuccess: false,
    paymentStatusData: null
  }
}

export const checkTransactionStatusSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    checkingPaymentStatus: false,
    checkPaymentStatusSuccess: true,
    paymentStatusData: action?.responseData
  }
}

export const checkTransactionStatusFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    checkingPaymentStatus: false,
    checkPaymentStatusSuccess: false,
    paymentStatusData: null
  }
}

export const checkOrderPaymentStatus = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    checkingOrderPaymentStatus: true,
    orderPaymentStatusCheckSuccess: false
  }
}

export const checkOrderPaymentStatusSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    checkingOrderPaymentStatus: false,
    orderPaymentStatusCheckSuccess: true,
    paymentTimeup: false,
    error: null,
  }
}

export const checkOrderPaymentStatusFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    checkingOrderPaymentStatus: false,
    orderPaymentStatusCheckSuccess: false,
    paymentTimeup: false,
    error: action.error
  }
}

export const HANDLERS = {
  [Types.MAKE_PAYMENT_REQUEST]: makePaymentRequest,
  [Types.MAKE_PAYMENT_SUCCESS]: makePaymentSuccess,
  [Types.MAKE_PAYMENT_FAILURE]: makePaymentFailure,
  [Types.MAKE_TEST_PAYMENT_REQUEST]: makeTestPaymentRequest,
  [Types.MAKE_TEST_PAYMENT_SUCCESS]: makeTestPaymentSuccess,
  [Types.MAKE_TEST_PAYMENT_FAILURE]: makeTestPaymentFailure,
  [Types.COMPLETE_PAYMENT]: completePayment,
  [Types.SET_PAYMENT_PROCESSING]: setPaymentProcessing,
  [Types.SET_PAYMENT_TIMEUP]: setPaymentTimeup,
  [Types.RESET_PAYMENT]: resetPayment,
  [Types.WAIT_FOR_PAYMENT]: waitForPayment,

  [Types.SET_FAKE_MOMO_PAYMENT_PIN]: setFakeMomoPaymentPin,

  [Types.SET_FAKE_MOMO_PAYMENT_MODAL_VISIBLE]: setFakeMomoPaymentModalVisible,
  [Types.MAKE_FAKE_PAYMENT]: makeFakePayment,
  [Types.MAKE_FAKE_PAYMENT_SUCCESS]: makeFakePaymentSuccess,
  [Types.MAKE_FAKE_PAYMENT_FAILURE]: makeFakePaymentFailure,

  [Types.CANCEL_FAKE_PAYMENT]: cancelFakePayment,
  [Types.CANCEL_FAKE_PAYMENT_SUCCESS]: cancelFakePaymentSuccess,
  [Types.CANCEL_FAKE_PAYMENT_FAILURE]: cancelFakePaymentFailure,

  [Types.CHECK_TRANSACTION_STATUS]: checkTransactionStatus,
  [Types.CHECK_TRANSACTION_STATUS_SUCCESS]: checkTransactionStatusSuccess,
  [Types.CHECK_TRANSACTION_STATUS_FAILURE]: checkTransactionStatusFailure,

  [Types.CHECK_ORDER_PAYMENT_STATUS]: checkOrderPaymentStatus,
  [Types.CHECK_ORDER_PAYMENT_STATUS_SUCCESS]: checkOrderPaymentStatusSuccess,
  [Types.CHECK_ORDER_PAYMENT_STATUS_FAILURE]: checkOrderPaymentStatusFailure
};

export default createReducer(INITIAL_STATE, HANDLERS);
