import { createReducer } from "reduxsauce";
import Types from "./actionTypes";
import get from "lodash/get";

export const INITIAL_STATE = {
  order: [],
  tax: 0,
  sub_total: 0,
  total: 0,
  order_id: null,
  order_name: null,
  currency: null,
  is_sending_order: false,
  order_changed: false,
  order_item_price_changed: false,
  order_item_price_list_changed: false,
  order_item_missing: false,
  order_less_stock: false,
  order_out_of_stock: false,
  order_item_blacklisted: false,
  order_sale_not_ok: false,
  order_item_unaccepted_products: false,
  no_connection: false,
  order_created: false,
  order_payment_method: null,
  errors_found: false,
  free_products: [],
  free_product_out_of_stock: false,
  free_product_not_ok: false,
  free_product_blacklisted: false,
  free_product_unaccepted_products: false,
  minimum_order_value_error: null,
  che: null,
  ordering_for: JSON.parse(localStorage.getItem("employee_ordering_for")) || null,
  posting_ordering_for: false,
  checking_cart_product_prices: false,
};

const incrementItemQuantity = (item) => {
  item.quantity++;
  return item;
};

const decrementItemQuantity = (item) => {
  if (item.quantity > 1) item.quantity--;
  return item;
};

const computeTotal = (order) => {
  const total = order
    .filter((item) => !item.out_of_stock)
    .reduce((acc, cur) => {
      return (acc = acc + cur.quantity * cur.lst_price);
    }, 0);
  return total;
};

const changeQuantity = (item, quantity) => {
  item.quantity = quantity;
  return item;
};

// export const addFreeProduct = (state = INITIAL_STATE, action) => {
//   const { product } = action
//   let order = state.order
//   let item = null;
//   item = order.find(it => it.id === product.odoo_id)
//   if(item) {
//     return {...state}
//   }
//   const fp = {
//     quantity: 1,
//     lst_price: 0,
//     id: product.odoo_id,
//     name: product.name
//   }
//   const products = order.push(fp)

//   return {...state}
// }

export const addFreeProducts = (state = INITIAL_STATE, action) => {
  const { products } = action
  return { ...state, free_products: products }
}

export const addItem = (state = INITIAL_STATE, action) => {
  let order = state.order;
  //let order_sale_not_ok = state.order_sale_not_ok;
  let item = null;
  // if(!action?.item.sale_ok) {
  //   message.error("Product cannot be sold")
  //   order_sale_not_ok = true;
  //   return { ...state, order_sale_not_ok }
  // }
  item = order.find(
    (order_item) => order_item.id === (action.item && action.item.id)
  );
  
  if (item) {
    if (
      (item.quantity === 1 && action.action === "decrement") ||
      (action.quantity === 0 && action.action === "change_quantity")
    )
      order = order.filter((order_item) => order_item.id !== item.id);
    else
      order = order.reduce((acc, cur) => {
        return (acc = [
          ...acc,
          cur.id === item.id
            ? action.action === "change_quantity"
              ? changeQuantity(item, action.quantity)
              : action.action === "increment"
              ? incrementItemQuantity(item)
              : decrementItemQuantity(item)
            : cur,
        ]);
      }, []);
  } else {
    action.item.out_of_stock = false;
    if (action.action === "increment") {
      action.item.quantity = 1;
      order.push(action.item);
    } else if (action.action === "decrement") {
      action.item.quantity = 0;
    } else {
      changeQuantity(action.item, action.quantity);
      order.push(action.item);
    }
  }
  let total = computeTotal(order);
  return { ...state, order, sub_total: total, total };
};

export const incrementItem = (state = INITIAL_STATE, action) => {
  let order = state.order;
  order = order.reduce((acc, cur) => {
    return (acc = [...acc, cur.id === action.id ? incrementItemQuantity(cur) : cur]);
  }, []);
  let total = computeTotal(order);
  return { ...state, order, sub_total: total, total };
};

export const decrementItem = (state = INITIAL_STATE, action) => {
  let order = state.order;
  order = order.reduce((acc, cur) => {
    return (acc = [...acc, cur.id === action.id ? decrementItemQuantity(cur) : cur]);
  }, []);
  let total = computeTotal(order);
  return { ...state, order, sub_total: total, total };
};

export const removeItem = (state = INITIAL_STATE, action) => {
  let order = state.order;
  order = order.filter((item) => item.id !== action.id);
  let total = computeTotal(order);
  return { ...state, order, sub_total: total, total };
};

export const setCurrency = (state = INITIAL_STATE, action) => {
  let order = state.order;
  return { ...state, currency: get(order[0], "currency.name") };
};

export const sendOrder = (state = INITIAL_STATE, action) => {
  // modify the existing order object... so all previous errors are removed...
  return {
    ...state,
    is_sending_order: true,
    order_item_price_changed: false,
    order_item_missing: false,
    order_less_stock: false,
    order_out_of_stock: false,
    order_sale_not_ok: false,
    no_connection: false,
    order_changed: false,
    order_created: false,
    order_payment_method: null,
    errors_found: false,
    free_product_out_of_stock: false,
    free_product_not_ok: false,
  };
};

export const sendOrderSuccess = (state = INITIAL_STATE, action) => {
  let response = action.responseData;
  return {
    ...state,
    order_id: response.id,
    order_name: response.name,
    order_created: true,
    order_payment_method: response.payment_method,
    is_sending_order: false,
    errors_found: false,
  };
};

export const sendOrderFailure = (state = INITIAL_STATE, action) => {
  let order = state.order;
  let newOrders = action.error;
  let order_changed = false;
  let order_item_price_changed = false;
  let order_item_price_list_changed = false;
  let order_item_missing = false;
  let order_less_stock = false;
  let order_out_of_stock = false;
  let order_item_blacklisted = false;
  let order_sale_not_ok = false;
  let order_item_unaccepted_products = false;
  let no_connection = false;
  let free_products = state.free_products;
  let free_product_out_of_stock = false;
  let free_product_not_ok = false;
  let free_product_blacklisted = false;
  let free_product_unaccepted_products = false;
  let total = computeTotal(state.order);
  // modify the existing order object...
  if (Array.isArray(newOrders)) {
    order = order.reduce((acc, cur) => {
      let existingOrderArray =
        newOrders &&
        newOrders.length > 0 &&
        newOrders.filter((_order) => _order.product_id === cur.id);
      if (existingOrderArray && existingOrderArray.length === 1) {
        let orderChanges = existingOrderArray[0].errors;

        Object.keys(orderChanges).forEach((key) => {
          if (key === "price_changed") {
            cur.old_price = cur.lst_price;
            cur.lst_price = orderChanges[key];
            cur.new_price = orderChanges[key];
            //cur.price_list_rule = ((existingOrderArray[0] || {}).price_list_rule) || false
            cur.price_list_rule = true
            cur.price_changed = true;
            order_item_price_changed = true;
          }
          if (key === "price_list_changed") {
            cur.old_price = cur.lst_price;
            cur.lst_price = orderChanges[key];
            cur.new_price = orderChanges[key];
            cur.price_list_rule = true
            cur.price_changed = true;
            cur.price_list_rule = true
            order_item_price_list_changed = true;
          }
          if (key === "available_quantity") {
            cur.old_quantity = cur.quantity;
            cur.quantity = orderChanges[key];
            cur.new_quantity = orderChanges[key];
            order_less_stock = true;
          }
          if (key === "out_of_stock") {
            cur.out_of_stock = true;
            cur.quantity = 0;
            order_out_of_stock = true;
          }
          if (key === "blacklisted") {
            cur.blacklisted = true;
            cur.quantity = 0;
            order_item_blacklisted = true;
          }
          if(key === "sale_ok") {
            cur.sale_ok = false
            cur.quantity = 0
            order_sale_not_ok = true
          }
          if(key === "unaccepted_products") {
            cur.unaccepted_products = true;
            cur.quantity = 0;
            order_item_unaccepted_products = true;
          }
          if (key === "exists") {
            cur.exists = orderChanges[key];
            order_item_missing = true;
          } else {
            cur.exists = true;
          }
        });
        order_changed = true;
      } else {
        cur.price_changed = false;
        cur.exists = true;
      }
      return (acc = [...acc, cur]);

    }, []);

    total = computeTotal(order);

    //lets handle free products
    free_products = free_products.reduce((acc, cur) => {
      let existingOrderArray =
        newOrders &&
        newOrders.length > 0 &&
        newOrders.filter((_order) => _order.product_id === cur.odoo_id && _order.free_product);
      if (existingOrderArray && existingOrderArray.length === 1) {
        let orderChanges = existingOrderArray[0].errors;

        Object.keys(orderChanges).forEach((key) => {
          if (key === "out_of_stock") {
            cur.out_of_stock = true;
            cur.quantity = 1;
            free_product_out_of_stock = true;
          }
          if(key === "sale_ok") {
            cur.out_of_stock = false
            cur.sale_ok = false
            cur.quantity = 1
            free_product_not_ok = true
          }
          if(key === "blacklisted") {
            cur.blacklisted = true
            free_product_blacklisted = true
          }
          if(key === "unaccepted_products") {
            cur.unaccepted_products = true;
            cur.quantity = 0;
            free_product_unaccepted_products = true;
          }
        });
      } else {
        cur.sale_ok = true;
        cur.out_of_stock = false
      }
      return (acc = [...acc, cur]);

    }, []);
    
  } else {
    const hasMinOrderError = action?.error.indexOf("Minimum order value is") === 0;
    if(hasMinOrderError) {
      no_connection = false;
    } else {
      no_connection = action.error ? true : false;
    }  
  }

  console.log('cart order props', order)

  return {
    ...state,
    order,
    total,
    order_changed,
    order_item_price_changed,
    order_item_missing,
    order_less_stock,
    order_out_of_stock,
    order_item_blacklisted,
    order_sale_not_ok,
    no_connection,
    order_created: false,
    order_payment_method: null,
    is_sending_order: false,
    errors_found: true,
    free_products,
    free_product_out_of_stock,
    free_product_not_ok,
    free_product_blacklisted,
    free_product_unaccepted_products,
    order_item_unaccepted_products,
    order_item_price_list_changed
  };
};

export const cleanUnacceptedProductsFromCart = (state = INITIAL_STATE, action) => {
  const order = state.order
                  .filter(order_item => !order_item.unaccepted_products)
                  .filter(order_item => !order_item.blacklisted)
  return {
    ...state,
    order
  }
}

export const resetOrder = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    order: [],
    tax: 0,
    sub_total: 0,
    total: 0,
    order_id: null,
    is_sending_order: false,
    order_changed: false,
    order_item_price_changed: false,
    order_item_price_list_changed: false,
    order_item_missing: false,
    order_less_stock: false,
    order_out_of_stock: false,
    order_item_blacklisted: false,
    order_item_unaccepted_products: false,
    order_created: false,
    errors_found: false,
    free_products: [],
    che: null,
    ordering_for: JSON.parse(localStorage.getItem("employee_ordering_for")) || null,
    checking_cart_product_prices: false
  };
};

export const resetOrderError = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    order_id: null,
    is_sending_order: false,
    order_changed: false,
    order_item_price_changed: false,
    order_item_price_list_changed: false,
    order_item_missing: false,
    order_less_stock: false,
    order_out_of_stock: false,
    order_item_blacklisted: false,
    order_item_unaccepted_products: false,
    // order_created: false,
    errors_found: false,
    no_connection: false,
    posting_ordering_for: false,
  };
};

export const updateChe = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    che: action.che
  }
}

export const setOrderingFor = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    posting_ordering_for: true
  }
}
export const setOrderingForSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ordering_for: action.data,
    posting_ordering_for: false
  }
}
export const setOrderingForFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    posting_ordering_for: false
  }
}

export const checkCartProductPrice = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    checking_cart_product_prices: true
  }
}

export const checkCartProductPriceSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    checking_cart_product_prices: false
  }
}
export const checkCartProductPriceFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    checking_cart_product_prices: false
  }
}

export const HANDLERS = {
  [Types.ADD_ITEM]: addItem,

  [Types.INCREMENT_ITEM]: incrementItem,

  [Types.DECREMENT_ITEM]: decrementItem,

  [Types.REMOVE_ITEM]: removeItem,

  [Types.SET_CURRENCY]: setCurrency,

  [Types.SEND_ORDER]: sendOrder,
  [Types.SEND_ORDER_SUCCESS]: sendOrderSuccess,
  [Types.SEND_ORDER_FAILURE]: sendOrderFailure,

  [Types.ADD_FREE_PRODUCTS]: addFreeProducts,

  [Types.RESET_ORDER]: resetOrder,

  [Types.SET_ORDERING_FOR]: setOrderingFor,
  [Types.SET_ORDERING_FOR_SUCCESS]: setOrderingForSuccess,
  [Types.SET_ORDERING_FOR_FAILURE]: setOrderingForFailure,

  [Types.CHECK_CART_PRODUCT_PRICE]: checkCartProductPrice,
  [Types.CHECK_CART_PRODUCT_PRICE_SUCCESS]: checkCartProductPriceSuccess,
  [Types.CHECK_CART_PRODUCT_PRICE_FAILURE]: checkCartProductPriceFailure,

  [Types.CLEAN_UNACCEPTED_PRODUCTS_FROM_CART]: cleanUnacceptedProductsFromCart,

  [Types.RESET_ORDER_ERROR]: resetOrderError,
};

export default createReducer(INITIAL_STATE, HANDLERS);


