import { createActions } from "reduxsauce";

export const { Types, Creators } = createActions(
  {
    activateLoginRequest: ["phone_number", "password"],
    activateLoginSuccess: ["responseData"],
    activateLoginFailure: ["error"],
    setActivateLoginKey: ["key"],

    setPinRequest: ["pin", "key"],
    setPinSuccess: ["responseData"],
    setPinFailure: ["error"],

    loginRequest: ["phone_number", "pin"],
    loginSuccess: ["responseData"],
    loginFailure: ["error"],

    launchRequest: ["phone_number"],
    launchSuccess: ["responseData"],
    launchFailure: ["error"],

    refreshTokenRequest: ["refresh_token"],
    refreshTokenSuccess: ["responseData"],
    refreshTokenFailure: ["error"],


    refreshExpiredToken: ["refresh_token"],
    refreshExpiredTokenSuccess: ["responseData"],
    refreshExpiredTokenFailure: ["error"],

    changePinRequest: ["old_pin", "new_pin"],
    changePinSuccess: ["responseData"],
    changePinFailure: ["error"],

    getUserDetails: ["id"],
    getUserDetailsSuccess: ["responseData"],
    getUserDetailsFailure: ["error"],

    getUserDetailsWithSalt: ["id"],
    getUserDetailsWithSaltSuccess: ["responseData"],
    getUserDetailsWithSaltFailure: ["error"],

    saveDeviceToken: ["registration_id"],
    saveDeviceTokenSuccess: ["responseData"],
    saveDeviceTokenFailure: ["error"],

    saveDefaultPhoneNumber: ["userId","payload"],
    saveDefaultPhoneNumberSuccess: ["responseData"],
    saveDefaultPhoneNumberFailure: ["error"],

    activateUserByQrcode: ["payload"],
    activateUserByQrcodeSuccess: ["responseData"],
    activateUserByQrcodeFailure: ["error"],

    setOrderingForData: ["che"],


    fetchOrderingFor: ["query"],
    fetchOrderingForSuccess: ["responseData"],
    fetchOrderingForFailure: ["error"],

    resetQrcodeScan: null,
  
    

    logout: null,
  },
  {}
);
