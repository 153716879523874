import { call, put, takeLatest, takeLeading } from "redux-saga/effects";
import { Creators, Types } from "../../redux/auth/actions";
import {
	activateLogin as activateLoginApi,
	setPin as setPinApi,
	login as loginApi,
  launch as launchApi,
	changePin as changePinApi,
	getUserDetails as getUserDetailsApi,
	saveDeviceToken as saveDeviceTokenApi,
	refreshToken as refreshTokenAPi,
	getUserDetailsWithSalt as getUserDetailsWithSaltApi,
	saveDefaultPhoneNumber as saveDefaultPhoneNumberApi,
  activateByQrcode as activateByQrcodeApi,
  refreshExpiredToken as refreshExpiredTokenApi
} from "../../api/auth";
import {
  fetchUsers as fetchUsersApi
} from "../../api/user"
import { getError, handleError, getErrorMessageFromResponse } from "../../../util/helpers/handleError";
import { history } from "../../../util/helpers/browserHistory";
// import { getPhoneNumberFromLocalStore } from "../../local-store";
import { message } from "antd";
import { rollbar } from "../../../util/configs";
// import { getRefreshToken, getToken } from "../../api/axiosDefaults";

export function* activateLogin(actions) {
	try {
		const { phone_number, password } = actions;
		const response = yield call(activateLoginApi, { phone_number, password });
		yield put(Creators.activateLoginSuccess(response.data.key));
		history.push("/set-pin", { key: response.data.key });
	} catch (e) {
		rollbar.error(`activate Login ${e}`)
		yield put(Creators.activateLoginFailure(handleError(e)));
	}
}

export function* setPin(actions) {
	const phone_number = localStorage.getItem('phone_number');
	localStorage.clear();
	if (phone_number) localStorage.setItem('phone_number', phone_number);
	const { pin, key } = actions;
	rollbar.info(`Set PIN by user with phone number: ${phone_number}`)
	try {
		const response = yield call(setPinApi, { pin, key });
		yield put(Creators.setPinSuccess(response.data));
		rollbar.info(`Set pin phone number: ${phone_number} response: ${response}`)
		history.push("/login");
	} catch (e) {
		rollbar.error(`Set Pin Error ${e}`)
		yield put(Creators.setPinFailure(handleError(e)));
	}
}

export function* login(actions) {
	const { phone_number, pin } = actions;
	//rollbar.info(`Login request: ${{phone_number:phone_number}}`)
	try {
		const response = yield call(loginApi, { phone_number, pin });
		rollbar.info(`Login response: ${response}`)
		yield put(Creators.loginSuccess(response.data));
		localStorage.setItem("auth", JSON.stringify(response.data));
		yield put(Creators.getUserDetails());
		history.push("/dashboard"); 
	} catch (e) {
		rollbar.error(`Login Error ${e}`)
    const errMsg = getErrorMessageFromResponse(e)
		yield put(Creators.loginFailure(handleError(e)));
    if(errMsg === 'Contact admin to activate your account') {
      history.push('/')
    }
    
	}
}

export function* launch(actions) {
	const { phone_number } = actions;
	rollbar.info(`Login request: ${{launch: phone_number}}`)
	try {
		const response = yield call(launchApi, {launch: phone_number });
		rollbar.info(`Login response: ${response}`)
		yield put(Creators.launchSuccess(response.data));
		localStorage.setItem("auth", JSON.stringify(response.data));
		yield put(Creators.getUserDetails());
		history.push("/dashboard");
	} catch (e) {
		rollbar.error(`Login Error ${e}`)
    const errMsg = getErrorMessageFromResponse(e) || e?.response?.data?.launch[0]
		yield put(Creators.launchFailure(handleError(e)));
    
    if(errMsg === 'Contact admin to activate your account') {
      history.push('/')
    } else {
      if(errMsg === "CHE not found") {
        //localStorage.removeItem("phone_number")
      }
      history.push("/login")
    }
    
	}
}

export function* refreshToken(actions) {
	try {
		const { refresh_token } = actions;
		let persistedAuthdata = JSON.parse(localStorage.getItem('auth'));
		delete persistedAuthdata.access;
		localStorage.setItem("auth", JSON.stringify(persistedAuthdata));
		const response = yield call(refreshTokenAPi, { refresh_token });
		yield put(Creators.refreshTokenSuccess(response.data));
    if(response?.data?.access_token) {
      let auth = localStorage.getItem('auth')
      let jauth = JSON.parse(auth)
      jauth.access = response.data.access_token;
      localStorage.setItem("auth", JSON.stringify(jauth));
    }
		yield put(Creators.getUserDetails());
		window.location = "/dashboard";
	} catch (e) {
    rollbar.error(`Refresh token error ${e}`)
    yield put(Creators.refreshTokenFailure(e));

    const errMsg = e?.response?.data?.non_field_errors[0] || e?.response?.data?.message
    if(errMsg && errMsg === 'Invalid refresh token') {
      history.push('/refresh-token')
    }
  
    
    // if(!getToken()) {
    //   history.push('/login')
    // } else if(!getPhoneNumberFromLocalStore()) {
		// 	window.location = "/login"
		// } else if(getRefreshToken()) {
		// 	history.push('/refresh-token')
		// } else {
    //   history.push('/login')
    // }
	}
}

export function* changePin(actions) {
	const { old_pin, new_pin } = actions;
	rollbar.info(`Change PIN request`)
	try {
		const response = yield call(changePinApi, { old_pin, new_pin });
		yield put(Creators.changePinSuccess(response.data));
		history.push("/change-pin-success");
	} catch (e) {
		rollbar.error(`Change Pin Error ${e}`)  
		yield put(Creators.changePinFailure(handleError(e)));
	}
}

export function* getUserDetails(actions) {
	try {
		const { id } = actions;
		const response = yield call(getUserDetailsApi, id);
		const resp = response && response.data;
		// if(resp?.id) {
		// 	localStorage.removeItem('auth');
		// 	localStorage.removeItem('he-che-user')
		// 	history.push('/logout')
		// } else {
		// 	localStorage.setItem("he-che-user", JSON.stringify(resp));
		// 	yield put(Creators.getUserDetailsSuccess(resp));
		// }
    if(resp?.id) {
      localStorage.setItem("he-che-user", JSON.stringify(resp));
		  yield put(Creators.getUserDetailsSuccess(resp));
      yield put(Creators.setOrderingForData(resp.ordering_for))
    }
		
	} catch (error) {
		rollbar.error(`Get User Details Error ${error}`)
		let errorsFound = error.response && error.response.data;
		yield put(Creators.getUserDetailsFailure(errorsFound));
	}
}

export function* getUserDetailsWithSalt(actions) {
	try {
		const { id } = actions;
		const response = yield call(getUserDetailsWithSaltApi, id);
		const resp = response && response.data;
    if(resp?.id) {
      localStorage.setItem("he-che-user", JSON.stringify(resp));
		  yield put(Creators.getUserDetailsWithSaltSuccess(resp));
      yield put(Creators.setOrderingForData(resp.ordering_for))
    }
	} catch (error) {
		rollbar.error(`Get User Details With Salt Error ${error}`)
		let errorsFound = error.response && error.response.data;
		yield put(Creators.getUserDetailsWithSaltFailure(errorsFound));
	}
}

export function* saveDeviceToken(actions) {
	try {
		const { registration_id } = actions;
		const response = yield call(saveDeviceTokenApi, { registration_id });
		yield put(Creators.saveDeviceTokenSuccess(response));
		localStorage.setItem("token_saved", true)
	} catch (error) {
		rollbar.error(`Save Device Token Error ${error}`)
		let errorsFound = error.response && error.response.data;
		yield put(Creators.saveDeviceTokenFailure(errorsFound));
	}
}

export function* saveDefaultPhoneNumber(actions) {
	const { userId, payload } = actions
	try {
		const response = yield call(saveDefaultPhoneNumberApi, userId, payload)
		const responseData = response?.data?.data;
		message.info("Default payment phone number saved")
		yield put(Creators.saveDefaultPhoneNumberSuccess(responseData))
		yield put(Creators.getUserDetailsWithSalt(userId));
	} catch (error) {
		rollbar.error(`Save default phone number error ${error}`)
		let errorMessage = getError(error)
		yield put(Creators.saveDefaultPhoneNumberFailure(errorMessage))
	}
}

export function* activateUserByQrcode(actions) {
	const { payload } = actions
	try {
		const response = yield call(activateByQrcodeApi, payload)
		const responseData = response?.data;
		yield put(Creators.activateUserByQrcodeSuccess(responseData?.key))

    if(responseData.key) {
      const phoneNumber = (responseData.email.split("@") || [])[0]
      if(phoneNumber) {
        localStorage.setItem("phone_number", phoneNumber)
      }
      message.info(responseData.message)
      history.push("/set-pin", { key: responseData?.key});
    } else {
      history.push("/login")
    }
    
	} catch (error) {
		rollbar.error(`Save default phone number error ${error}`)
		let errorMessage = error?.response?.data?.error || getError(error) || 'User Activation Failed!'
    message.error(errorMessage)
		yield put(Creators.activateUserByQrcodeFailure(errorMessage))
	}
}

export function* refreshExpiredToken(actions) {
	try {
		const { refresh_token } = actions; 
		//let persistedAuthdata = JSON.parse(localStorage.getItem('auth'));
		//delete persistedAuthdata.access;
		//localStorage.setItem("auth", JSON.stringify(persistedAuthdata));
		const response = yield call(refreshExpiredTokenApi, { refresh_token });
		yield put(Creators.refreshExpiredTokenSuccess(response.data));
    
		let auth = localStorage.getItem('auth')
		let jauth = JSON.parse(auth)
    if(response?.data?.access_token) {
      const authData = {...jauth, access: response?.data?.access_token}
      localStorage.setItem("auth", JSON.stringify(authData));
    }
		yield put(Creators.getUserDetails());
    history.push('/dashboard')
		//window.location = "/dashboard";
	} catch (e) {
		rollbar.error(`Refresh expired token error ${e}`)
		yield put(Creators.refreshExpiredTokenFailure(e));
	}
}

export function* fetchOrderingFor(action) {
  try {
    const { query } = action
    const response = yield call(fetchUsersApi, query)
    const che = ((response?.data?.results || [])
      .filter(it => it?.odoo_id === query.search_term) || [])[0]
    yield put(Creators.fetchOrderingForSuccess(che))
  } catch(e) {
    yield put(Creators.fetchOrderingForFailure("Failed to search for che"))
  }
}

export function* watchActivateLogin() {
	yield takeLeading(Types.ACTIVATE_LOGIN_REQUEST, activateLogin);
}

export function* watchSetPin() {
	yield takeLeading(Types.SET_PIN_REQUEST, setPin);
}

export function* watchLogin() {
	yield takeLeading(Types.LOGIN_REQUEST, login);
}

export function* watchLaunch() {
	yield takeLeading(Types.LAUNCH_REQUEST, launch);
}

export function* watchRefreshToken() {
	yield takeLeading(Types.REFRESH_TOKEN_REQUEST, refreshToken);
}

export function* watchRefreshExpiredToken() {
	yield takeLeading(Types.REFRESH_EXPIRED_TOKEN, refreshExpiredToken);
}

export function* watchChangePin() {
	yield takeLeading(Types.CHANGE_PIN_REQUEST, changePin);
}

export function* watchGetUserDetails() {
	yield takeLeading(Types.GET_USER_DETAILS, getUserDetails);
}

export function* watchSaveDeviceToken() {
	yield takeLeading(Types.SAVE_DEVICE_TOKEN, saveDeviceToken);
}

export function* watchGetUserDetailsWithSalt() {
	yield takeLeading(Types.GET_USER_DETAILS_WITH_SALT, getUserDetailsWithSalt)
}


export function* watchSaveDefaultPhoneNumber() {
	yield takeLeading(Types.SAVE_DEFAULT_PHONE_NUMBER, saveDefaultPhoneNumber)
}

export function* watchActivateUserByQrcode() {
  yield takeLatest(Types.ACTIVATE_USER_BY_QRCODE, activateUserByQrcode)
}

export function* watchFetchOrderingFor(action) {
  yield takeLatest(Types.FETCH_ORDERING_FOR, fetchOrderingFor)
}


