import { createReducer } from "reduxsauce";
import Types from "./actionTypes";

export const INITIAL_STATE = {
  loading: false,
  posting: false,
  post_success: false,
  users: [],
  user: null,
  error: null
};

export const fetchUsers = (state = INITIAL_STATE, action) => {
  return { ...state, loading: true, users: [] };
};

export const fetchUsersSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    users: action?.responseData,
    loading: false,
    error: null
  };
};

export const fetchUsersFailure = (state = INITIAL_STATE, action) => {
  return { ...state, users: [], loading: false, error: action?.error };
};


export const updateUser = (state = INITIAL_STATE, action) => {
  return { ...state, posting: true, post_success: false };
};

export const updateUserSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    posting: false,
    post_success: true,
    error: null
  };
};

export const updateUserFailure = (state = INITIAL_STATE, action) => {
  return { ...state, posting: false, error: action?.error, post_success: false };
};

export const resetUser = (state = INITIAL_STATE, action) => {
  return {...state, loading: false, users: [], user: null, error: null}
}


export const HANDLERS = {
  [Types.FETCH_USERS]: fetchUsers,
  [Types.FETCH_USERS_SUCCESS]: fetchUsersSuccess,
  [Types.FETCH_USERS_FAILURE]: fetchUsersFailure,
  [Types.UPDATE_USER]: updateUser,
  [Types.UPDATE_USER_SUCCESS]: updateUserSuccess,
  [Types.UPDATE_USER_FAILURE]: updateUserFailure,
  [Types.RESET_USER]: resetUser,
};

export default createReducer(INITIAL_STATE, HANDLERS);
