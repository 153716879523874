import { call, put, takeLatest, takeLeading } from "redux-saga/effects";
import { Creators, Types } from "../../redux/accounting/actions";
import { fetchCreditBalance as fetchCreditBalanceApi, 
  fetchOpenPayments as fetchOpenPaymentsApi,
	fetchCreditBalanceSalt as fetchCreditBalanceSaltApi, fetchInvoices as fetchInvoicesApi } from "../../api/accounting";
import { getErrorMessageFromResponse, handleError } from "../../../util/helpers/handleError";
import { get } from "lodash";
import { rollbar } from "../../../util/configs";

export function* fetchCreditBalance(actions) {
	try {
		const response = yield call(fetchCreditBalanceApi);
		yield put(Creators.fetchCreditBalanceSuccess(response?.data));
	} catch (e) {
		rollbar.error(`Error fetching credit balance ${e}`)
		if (get(e, "response.data.errors")) {
			yield put(Creators.fetchCreditBalanceFailure(get(e, "response.data.errors")));
		} else {
			if (e.response && e.response.status === 500) {
				handleError(e);
				yield put(Creators.fetchCreditBalanceFailure());
			} else {
				handleError(e);
				yield put(Creators.fetchCreditBalanceFailure(getErrorMessageFromResponse(e)));
			}
		}
	}
}

export function* fetchInvoices(action) {
	const {query} = action
	try {
		const response = yield call(fetchInvoicesApi, query)
		const {data} = response
		const { results, count, next } = data
		yield put(Creators.fetchInvoicesSuccess(results, count, next ? true : false, next))
	} catch (e) {
		rollbar.error(`Error fetching credit invoices  ${e}`)
		if (get(e, "response.data.errors")) {
			yield put(Creators.fetchInvoicesFailure(get(e, "response.data.errors")));
		} else {
			if (e.response && e.response.status === 500) {
				handleError(e);
				yield put(Creators.fetchInvoicesFailure());
			} else {
				handleError(e);
				yield put(Creators.fetchInvoicesFailure(getErrorMessageFromResponse(e)));
			}
		
		}
	}
}

export function* fetchCreditBalanceSalt(actions) {
	try {
		const response = yield call(fetchCreditBalanceSaltApi);
		yield put(Creators.fetchCreditBalanceSuccess(response?.data));
	} catch (e) {
		rollbar.error(`Error fetching credit invoices with salt ${e}`)
		if (get(e, "response.data.errors")) {
			yield put(Creators.fetchCreditBalanceFailure(get(e, "response.data.errors")));
		} else {
			if (e.response && e.response.status === 500) {
				handleError(e);
				yield put(Creators.fetchCreditBalanceFailure());
			} else {
				handleError(e);
				yield put(Creators.fetchCreditBalanceFailure(getErrorMessageFromResponse(e)));
			}
		}
	}
}

export function* fetchOpenPayments(action) {
	const {query} = action
	try {
		const response = yield call(fetchOpenPaymentsApi, query)
		const {data} = response
    yield put(Creators.fetchOpenPaymentsSuccess(data))
	} catch (e) {
		rollbar.error(`Error fetching credit invoices  ${e}`)
		if (get(e, "response.data.errors")) {
			yield put(Creators.fetchOpenPaymentsFailure(get(e, "response.data.errors")));
		} else {
			if (e.response && e.response.status === 500) {
				handleError(e);
				yield put(Creators.fetchOpenPaymentsFailure());
			} else {
				handleError(e);
				yield put(Creators.fetchOpenPaymentsFailure(getErrorMessageFromResponse(e)));
			}
		}
	}
}

export function setTab(action) {
  const { tab } = action
  localStorage.setItem("ACCOUNTING_TAB", tab)
  put(Creators.setTab(tab))
}


export function* watchFetchCreditBalance() {
	yield takeLeading(Types.FETCH_CREDIT_BALANCE, fetchCreditBalance);
}

export function* watchFetchCreditBalanceSalt() {
	yield takeLeading(Types.FETCH_CREDIT_BALANCE_SALT, fetchCreditBalanceSalt);
}

export function* watchFetchInvoices() {
	yield takeLatest(Types.FETCH_INVOICES, fetchInvoices)
}

export function* watchFetchOpenPayments() {
	yield takeLatest(Types.FETCH_OPEN_PAYMENTS, fetchOpenPayments)
}

export function* watchSetTab() {
	yield takeLatest(Types.SET_TAB, setTab)
}