import { call, put, takeLeading } from 'redux-saga/effects';
import { Creators, Types } from '../../redux/payments/actions';
import {
	makePayment as makePaymentApi,
	makeTestPayment as makeTestPaymentApi,
  checkTransactionStatus as checkingPaymentStatusApi,
  orderPaymentStatusCheck as orderPaymentStatusCheckApi,
  checkTestOrderPaymentStatus as checkTestOrderPaymentStatusApi,
  
} from '../../api/payments';
import { handleError } from '../../../util/helpers/handleError';
import { CURRENT_URL } from '../../../util/helpers/reusableFunctions';
import { rollbar } from '../../../util/configs';
// import { roundUpFloat } from '../../../util/helpers/commonHelper';

export function* makePayment(actions) {
	const { data } = actions;
  console.log('-----data', data)
	try {
		if(data.payment_method === 'test') {
      rollbar.info(`Make test payment request: ${data}`)
			const response = yield call(makeTestPaymentApi, {...data, amount: data.amount.toFixed(0)})
			yield put(Creators.makePaymentSuccess(response?.data));
			yield put(Creators.setFakeMomoPaymentModalVisible(true))
		} else {
      //rollbar.info(`Make payment request: ${data}`)
			const response = yield call(makePaymentApi, {...data, amount: Math.ceil(data.amount)});
      //const response = yield call(makeTestPaymentApi, {...data})
			yield put(Creators.makePaymentSuccess(response.data));
      rollbar.info(`Make payment response: ${response?.data}`)
		}
	} catch (e) {
		rollbar.error(`Make payment error: ${e}`)
		yield put(Creators.makePaymentFailure(handleError(e)));
	}
}

export function* makeTestPayment(actions) {
	try {
		const { data } = actions;
		if(data.payment_method === 'test') {
			const response = yield call(makeTestPaymentApi, {...data})
			yield put(Creators.makePaymentSuccess(response.data));
		} else {
			const response = yield call(makePaymentApi, {...data});
			yield put(Creators.makePaymentSuccess(response.data));
		}
		
	} catch (e) {
		rollbar.error('Make test payment error', e)
		yield put(Creators.makePaymentFailure(handleError(e)));
	}
}

export function* checkOrderPaymentStatus(action) {
  try {
    const { payload, order_or_invoice } = action
    const response = yield call(orderPaymentStatusCheckApi, payload)
    const responseData = response?.data
    console.log('response form order paymets status', response, 'order_type', order_or_invoice)
    yield put(Creators.checkOrderPaymentStatusSuccess(responseData))
    //lets handle response

    const transactionId = responseData?.payment?.transaction_id
    const paymentStatus = responseData?.payment?.status

    const deliveryStart = responseData?.route?.delivery_start
    const deliveryEnd = responseData?.route?.delivery_end
    //const routeId = responseData?.route?.id

    if(responseData?.payment?.id) {

      if(order_or_invoice === "order") {
        //hanlde order notification
        //const orderName = responseData?.order?.name
        const orderDisplayName = responseData?.order?.display_name
        const orderState = responseData?.order?.state
        const invoiceId = (responseData?.order?.invoice_ids || [])[0]


        if((orderState === "done" || orderState === "sale") && invoiceId && paymentStatus === "successful") {
          //payment is complete or successful, lets display successful notification
          window.location = `${CURRENT_URL}/cart/successful/${orderDisplayName}?msg=${responseData?.message || 'Payment successful'}&delivery_start=${deliveryStart}&delivery_end=${deliveryEnd}&transaction_id=${transactionId}`
        } else {
          console.log('payment failed')
          //payment failed, lets display successful notification
          window.location = `${CURRENT_URL}/cart/failure/${responseData?.order_name}?msg=${responseData?.payment?.message || 'Payment failed'}`
        }
      } else {
        //handle invoice notification
        const status = responseData?.invoice?.state
        const ACCOUNTING_TAB = window.localStorage.getItem("ACCOUNTING_TAB") || 1
        const baseUrl = ACCOUNTING_TAB === 1 ? 'credit-invoices' : 'open-payments'

        if(status === "done" || status === "sale") {
          window.location = `${CURRENT_URL}/${baseUrl}/?status=successful&invoice_id=${responseData.invoice?.id}&msg=${responseData?.payment?.message}&amount=${responseData?.payment?.amount}&transaction_id=${responseData?.payment?.transaction_id}`
        } else {
          window.location = `${CURRENT_URL}/${baseUrl}/?status=failure&invoice_id=${responseData.invoice?.id}&msg=${responseData?.payment?.message}&amount=${responseData?.payment?.amount}&transaction_id=${responseData?.payment?.transaction_id}`
        }
      }
    }
    
  } catch(e){
    yield put(Creators.checkOrderPaymentStatusFailure(e))
  }
}


export function* checkTestOrderPaymentStatus(action) {
  try {
    const { payload, order_or_invoice } = action
    const response = yield call(checkTestOrderPaymentStatusApi, payload)

    console.log('response form order paymets status', response, 'order_type', order_or_invoice)
    const responseData = response?.data
    yield put(Creators.checkOrderPaymentStatusSuccess(responseData))
    //lets handle response

    const transactionId = responseData?.payment?.transaction_id
    const paymentStatus = responseData?.payment?.status

    const deliveryStart = responseData?.route?.delivery_start
    const deliveryEnd = responseData?.route?.delivery_end
    //const routeId = responseData?.route?.id

    if(responseData?.payment?.id) {

      if(order_or_invoice === "order") {
        //hanlde order notification
        //const orderName = responseData?.order?.name
        const orderDisplayName = responseData?.order?.display_name
        const orderState = responseData?.order?.state
        const invoiceId = (responseData?.order?.invoice_ids || [])[0]

        console.log('orderState', orderState, 'invoiceId', invoiceId, 'paymentStatus', paymentStatus)


        if((orderState === "done" || orderState === "sale") && invoiceId && paymentStatus === "successful") {
          
          //payment is complete or successful, lets display successful notification
          window.location = `${CURRENT_URL}/cart/successful/${orderDisplayName}?msg=${responseData?.message || 'Payment successful'}&delivery_start=${deliveryStart}&delivery_end=${deliveryEnd}&transaction_id=${transactionId}`
        } else {
          console.log('payment failed')
          //payment failed, lets display successful notification
          window.location = `${CURRENT_URL}/cart/failure/${responseData?.order_name}?msg=${responseData?.payment?.message || 'Payment failed'}`
        }
      } else {
        //handle invoice notification
        const status = responseData?.invoice?.state
        const ACCOUNTING_TAB = window.localStorage.getItem("ACCOUNTING_TAB") || 1
        const baseUrl = ACCOUNTING_TAB === 1 ? 'credit-invoices' : 'open-payments'

        if(status === "done" || status === "sale") {
          window.location = `${CURRENT_URL}/${baseUrl}/?status=successful&invoice_id=${responseData.invoice?.id}&msg=${responseData?.payment?.message}&amount=${responseData?.payment?.amount}&transaction_id=${responseData?.payment?.transaction_id}`
        } else {
          window.location = `${CURRENT_URL}/${baseUrl}/?status=failure&invoice_id=${responseData.invoice?.id}&msg=${responseData?.payment?.message}&amount=${responseData?.payment?.amount}&transaction_id=${responseData?.payment?.transaction_id}`
        }
      }
    }
  } catch(e){
    yield put(Creators.checkOrderPaymentStatusFailure(e))
  }
}

export function* checkTransactionStatus(action) {
  try {
    const { paymentId } = action
    const response = yield call(checkingPaymentStatusApi, paymentId)
    yield put(Creators.checkTransactionStatusSuccess(response))
  } catch (error) {
    yield put(Creators.checkTransactionStatusFailure(error))
  }
}

export function* makeFakePayment(actions) {
	const { data } = actions
  rollbar.info(`Test payment request: ${data}`)
	yield put(Creators.makeFakePaymentSuccess({code: 'success', data: data}))
  rollbar.info(`Test payment response:  ${{code: 'success', data: data}}`)
	setTimeout(() => {
		window.location = `${CURRENT_URL}/cart/successful/S01256?msg=Payment Successful`
	}, 120000)
}

export function* cancelFakePayment(actions) {
	const { data } = actions
	yield put(Creators.cancelFakePaymentSuccess({code: 'success', data: data}))
	setTimeout(() => {
		window.location = `${CURRENT_URL}/cart/failure/S01256?msg=Test Payment Failed`
	}, 2000)
}

export function* watchMakePayment() {
  yield takeLeading(Types.MAKE_PAYMENT_REQUEST, makePayment);
}

export function* watchMakeFakePayment() {
	yield takeLeading(Types.MAKE_FAKE_PAYMENT, makeFakePayment)
}

export function* watchCancelFakePayment() {
	yield takeLeading(Types.CANCEL_FAKE_PAYMENT, cancelFakePayment)
}

export function* watchCheckTransactionStatus() {
  yield takeLeading(Types.CHECK_TRANSACTION_STATUS, checkTransactionStatus)
}

export function* watchCheckOrderPaymentStatus() {
  yield takeLeading(Types.CHECK_ORDER_PAYMENT_STATUS, checkOrderPaymentStatus)
}

