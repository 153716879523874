import { axiosInstance } from './axiosDefaults';

export const apiRequest = (method, url, data={}) => {
	const response = axiosInstance()({
	  method,
	  url,
	  data,
	});
	return response;
}




export const customApiRequest = (method, url, data={}, timeout=60000) => {
	const response = axiosInstance()({
		method,
		url,
		data,
		timeout
	});
	return response
}