import { createActions } from "reduxsauce";

export const { Types, Creators } = createActions(
  {
    fetchCreditBalance: [],
    fetchCreditBalanceSuccess: ["responseData"],
    fetchCreditBalanceFailure: ["error"],
    fetchCreditBalanceSalt: [],

    fetchInvoices: ["query"],
    fetchInvoicesSuccess: ["results", "totalCount", "hasMore", "nextPage"],
    fetchInvoicesFailure: ['error'],

    resetInvoices: [],


    fetchOpenPayments: ["query"],
    fetchOpenPaymentsSuccess: ["results", "totalCount", "hasMore", "nextPage"],
    fetchOpenPaymentsFailure: ['error'],
    resetOpenPayments: [],

    setTab: ['tab']
  },
  {}
)