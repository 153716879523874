import React, { lazy, Suspense } from "react";
import "antd/dist/antd.less";
import { Router, Route, Switch } from "react-router-dom";
import { Layout, Spin } from "antd";
import './styles/credit-overview.less';
import { messages } from "./i18n/messages";

import { history } from "./util/helpers/browserHistory";
import { messageConfig } from "./util/configs";
import { notificationConfig } from "./util/configs";
import PrivateRoute from "./util/helpers/requireAuthentication";
import initializeAddToHomeScreen from "./util/helpers/addToHomeScreen";
import { messaging } from "./firebase";
import { CURRENT_URL } from "./util/helpers/reusableFunctions";
import SyncPayment from './containers/PrivateRoutes/Sync';
import SyncProducts from './containers/PrivateRoutes/SyncProducts'
import SyncUserGroup from "./containers/PrivateRoutes/SyncUserGroup";
import SyncPromotions from "./containers/PrivateRoutes/SyncPromotions";
import RefreshToken from "./containers/Auth/components/RefreshToken";
import { connect } from "react-redux";
import { IntlProvider } from "react-intl";
import { useEffect } from "react";

const retry = (fn, retriesLeft = 5, interval = 1000) => {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            reject(error);
            return;
          }

          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
};

const NotfoundPromise = import("./containers/NotFound");
const Notfound = lazy(() => retry(() => NotfoundPromise));

const ActivateLoginContainerPromise = import("./containers/Auth/ActivateLogin");
const ActivateLoginContainer = lazy(() =>
  retry(() => ActivateLoginContainerPromise)
);

const SetPinContainerPromise = import("./containers/Auth/SetPin");
const SetPinContainer = lazy(() => retry(() => SetPinContainerPromise));

const LoginContainerPromise = import("./containers/Auth/Login");
const LoginContainer = lazy(() => retry(() => LoginContainerPromise));

// const LaunchContainerPromise = import("./containers/Auth/Launch");
// const LaunchContainer = lazy(() => retry(() => LaunchContainerPromise));

const TokenRefreshContainerPromise = import("./containers/Auth/TokenRefresh")
const TokenRefreshContainer = lazy(() => retry(() => TokenRefreshContainerPromise))

const ChangePinContainerPromise = import("./containers/PrivateRoutes/ChangePin");
const ChangePinContainer = lazy(() => retry(() => ChangePinContainerPromise));

const PinSuccessFulPromise = import(
  "./containers/PrivateRoutes/ChangePin/components/PinSuccessful"
);
const PinSuccessFul = lazy(() => retry(() => PinSuccessFulPromise));

const ProductPromise = import("./containers/PrivateRoutes/Products");
const Products = lazy(() => retry(() => ProductPromise));

const CartPromise = import("./containers/PrivateRoutes/Cart");
const Cart = lazy(() => retry(() => CartPromise));

const OrdersPromise = import("./containers/PrivateRoutes/Orders/list-orders");
const Orders = lazy(() => retry(() => OrdersPromise));

const OrderDetailsPromise = import("./containers/PrivateRoutes/Orders/order-details");
const OrderDetails = lazy(() => retry(() => OrderDetailsPromise));

const LogoutPromise = import("./containers/PrivateRoutes/Logout");
const Logout = lazy(() => retry(() => LogoutPromise));

// const CreditOverviewPromise = import("./containers/PrivateRoutes/CreditOverview/invoice-list")
// const CreditOverview = lazy(() => retry(() => CreditOverviewPromise));

const CreditOverviewPromise = import("./containers/PrivateRoutes/CreditOverview")
const CreditOverview = lazy(() => retry(() => CreditOverviewPromise));

const OpenPaymentsOverviewPromise = import("./containers/PrivateRoutes/OpenPaymentOverview")
const OpenPaymentOverview = lazy(() => retry(() => OpenPaymentsOverviewPromise));

const ActivateUserByQrcodeContainerPromise = import('./containers/Auth/ActivateUserByQrcode')
const ActivateUserByQrcodeContainer = lazy(() => retry(() => ActivateUserByQrcodeContainerPromise))

const MaintenancePagePromise = import("./containers/Maintenance")
const MaintenancePage = lazy(() => MaintenancePagePromise)

const LaunchRefreshPromise = import("./containers/Auth/LauchRefresh")
const LaunchRefreshContainer = lazy(() => LaunchRefreshPromise)


const App = (props) => {
  //const locale = LOCALES.FRENCH;
  const locale = props?.user?.country?.language_code || "en"

  messageConfig();
  notificationConfig();
  messaging.onMessage((payload) => {
    if (payload && payload.notification && payload.notification.title === "Payment methods updated") {
      window.location = `${CURRENT_URL}/sync-payment`
    } else if ((payload && payload.notification && payload.notification.title === "Products list updated") ||
      (payload && payload.notification && payload.notification.title === "Products updated")) {
      window.location = `${CURRENT_URL}/sync-products`
    } else if (payload && payload.notification && payload.notification.title === "Product list for user group updated") {
      window.location = `${CURRENT_URL}/sync-user-group`
    } else if (payload?.notification?.title === "Promotions") {
      let payloadData = JSON.parse(payload?.data?.extra_kwargs)
      window.location = `${CURRENT_URL}/sync-promotions?refresh=${payloadData?.refresh || false}`
    } else {
      try {
        const paymentData = JSON.parse(payload.data.extra_kwargs); //
        let status = "details"
        if (paymentData.status === "failed") {
          status = "failure"
        } else if (paymentData.status === "successful") {
          status = "successful"
        }
        if (paymentData.order_id) {
          window.location = `${CURRENT_URL}/cart/${status}/${paymentData.order_name}?msg=${paymentData.message}&delivery_start=${paymentData?.delivery_start}&delivery_end=${paymentData?.delivery_end}&transaction_id=${paymentData?.transaction_id}`
        } else if (paymentData.invoice_id) {
          const ACCOUNTING_TAB = window.localStorage.getItem("ACCOUNTING_TAB") || 1
          const baseUrl = ACCOUNTING_TAB === 1 ? 'credit-invoices' : 'open-payments'
          window.location = `${CURRENT_URL}/${baseUrl}/?status=${status}&invoice_id=${paymentData.invoice_id}&msg=${paymentData.message}&amount=${paymentData.amount}&delivery_start=${paymentData?.delivery_start}&delivery_end=${paymentData?.delivery_end}&transaction_id=${paymentData?.transaction_id}`
        }
      } catch (err) {
        window.location = `${CURRENT_URL}/dashboard`
      }
    }
  })

  useEffect(() => {
    initializeAddToHomeScreen();
  })

  return (
    <IntlProvider messages={messages[locale]} locale={locale} defaultLocale="LOCALE.ENGLISH">
        <Layout>
          <Router history={history}>
            <Suspense fallback={<Spin />}>
              <Switch>
                <Route path="/" exact component={ActivateLoginContainer} >
                  
                </Route>
                {/* <Route path="/" exact component={MaintenancePage} >
                  
                  </Route> */}
                <Route path="/set-pin" exact component={SetPinContainer} />
                <Route path="/login" exact component={LoginContainer} />
                <Route path="/refresh-token" exact component={TokenRefreshContainer} />
                <Route path="/activate-by-qrcode" exact component={ActivateUserByQrcodeContainer} />
                <Route path="/maintenance" exact component={MaintenancePage} />
                <Route path="/refresh" exact component={RefreshToken} />
                <Route path="/launch-refresh" exact component={LaunchRefreshContainer} />
                <PrivateRoute
                  path="/change-pin"
                  exact
                  component={ChangePinContainer}
                />
                <PrivateRoute
                  path="/change-pin-success"
                  exact
                  component={PinSuccessFul}
                />
                <PrivateRoute path="/dashboard" exact component={Products} />
                <PrivateRoute path="/cart/:type/:orderId/" exact component={Cart} />
                <PrivateRoute path="/orders" exact component={Orders} />
                <PrivateRoute path="/order/:id" exact component={OrderDetails} />
                <PrivateRoute path="/sync-payment" exact component={SyncPayment} />
                <PrivateRoute path="/credit-invoices" exact component={CreditOverview} />
                <PrivateRoute path="/open-payments" exact component={OpenPaymentOverview} />
                <PrivateRoute path="/sync-products" exact component={SyncProducts} />
                <PrivateRoute path="/sync-promotions" exact component={SyncPromotions} />
                <PrivateRoute path="/sync-user-group" exact component={SyncUserGroup} />
                <PrivateRoute path="/logout" exact component={Logout} />
                <Route component={Notfound} />
              </Switch>
            </Suspense>
          </Router>
        </Layout>
      </IntlProvider>
  )
}

const mapStateToProps = store => ({
  isMakingPayment: store.payment.isMakingPayment,
  user: store?.auth?.user
})


export default connect(mapStateToProps, null)(App)