import { createTypes } from "reduxsauce";

export default createTypes(
  `
  MAKE_PAYMENT_REQUEST
  MAKE_PAYMENT_SUCCESS
  MAKE_PAYMENT_FAILURE
  COMPLETE_PAYMENT
  SET_PAYMENT_TIMEUP
  RESET_PAYMENT
  WAIT_FOR_PAYMENT
  SET_PAYMENT_PROCESSING

  MAKE_TEST_PAYMENT_REQUEST
  MAKE_TEST_PAYMENT_SUCCESS
  MAKE_TEST_PAYMENT_FAILURE

  MAKE_FAKE_PAYMENT
  MAKE_FAKE_PAYMENT_SUCCESS
  MAKE_FAKE_PAYMENT_FAILURE

  CANCEL_FAKE_PAYMENT
  CANCEL_FAKE_PAYMENT_SUCCESS
  CANCEL_FAKE_PAYMENT_FAILURE

  SET_FAKE_MOMO_PAYMENT_PIN

  CHECK_TRANSACTION_STATUS
  CHECK_TRANSACTION_STATUS_SUCCESS
  CHECK_TRANSACTION_STATUS_FAILURE

  CHECK_ORDER_PAYMENT_STATUS
  CHECK_ORDER_PAYMENT_STATUS_SUCCESS
  CHECK_ORDER_PAYMENT_STATUS_FAILURE

  SET_FAKE_MOMO_PAYMENT_MODAL_VISIBLE
`,
  {}
);
