import { createTypes } from "reduxsauce";

export default createTypes(
  `
  ACTIVATE_LOGIN_REQUEST
  ACTIVATE_LOGIN_SUCCESS
  ACTIVATE_LOGIN_FAILURE
  SET_ACTIVATE_LOGIN_KEY

  SET_PIN_REQUEST
  SET_PIN_SUCCESS
  SET_PIN_FAILURE

  LOGIN_REQUEST
  LOGIN_SUCCESS
  LOGIN_FAILURE

  LAUNCH_REQUEST
  LAUNCH_SUCCESS
  LAUNCH_FAILURE

  REFRESH_TOKEN_REQUEST
  REFRESH_TOKEN_SUCCESS
  REFRESH_TOKEN_FAILURE

  REFRESH_EXPIRED_TOKEN
  REFRESH_EXPIRED_TOKEN_SUCCESS
  REFRESH_EXPIRED_TOKEN_FAILURE

  CHANGE_PIN_REQUEST
  CHANGE_PIN_SUCCESS
  CHANGE_PIN_FAILURE

  GET_USER_DETAILS
  GET_USER_DETAILS_SUCCESS
  GET_USER_DETAILS_FAILURE

  GET_USER_DETAILS_WITH_SALT
  GET_USER_DETAILS_WITH_SALT_SUCCESS
  GET_USER_DETAILS_WITH_SALT_FAILURE

  SAVE_DEVICE_TOKEN
  SAVE_DEVICE_TOKEN_SUCCESS
  SAVE_DEVICE_TOKEN_FAILURE

  SAVE_DEFAULT_PHONE_NUMBER
  SAVE_DEFAULT_PHONE_NUMBER_SUCCESS
  SAVE_DEFAULT_PHONE_NUMBER_FAILURE

  FETCH_ORDERING_FOR
  FETCH_ORDERING_FOR_SUCCESS
  FETCH_ORDERING_FOR_FAILURE

  SET_ORDERING_FOR_DATA

  ACTIVATE_USER_BY_QRCODE
  ACTIVATE_USER_BY_QRCODE_SUCCESS
  ACTIVATE_USER_BY_QRCODE_FAILURE
  RESET_QRCODE_SCAN
 
  LOGOUT
`,
  {}
);
