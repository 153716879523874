import { apiRequest } from "../apiRequest";
import { ACCOUNTING_ENDPOINT } from "../urls";

export const fetchCreditBalance = (data) => {
	return apiRequest("GET", `${ACCOUNTING_ENDPOINT}/credit_balance`, );
};

export const fetchCreditBalanceSalt = (data) => {
	return apiRequest("GET", `${ACCOUNTING_ENDPOINT}/credit_balance?salt=${Math.floor(Date.now() / 1000)}`);
};

export const fetchInvoices = (query) => {
	const queryStr = query ? `?${query}` : ""
	return apiRequest("GET", `${ACCOUNTING_ENDPOINT}/invoices${queryStr}`);
}

export const fetchOpenPayments = (query) => {
	const queryStr = query ? `?${query}&open=true` : ""
	return apiRequest("GET", `${ACCOUNTING_ENDPOINT}/invoices${queryStr}`);
}
