import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { createLogger } from "redux-logger";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducer from "./rootReducer";
import rootSaga from "./services/sagas";

const logger = createLogger();

function isDev() {
	return process.env.NODE_ENV === "development";
}


const persistConfig = {
	key: "root",
	storage: storage,
	whitelist: ["cart"],
};
const pReducer = persistReducer(persistConfig, rootReducer);

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware, isDev() && logger].filter(Boolean);

const store = createStore(pReducer, applyMiddleware(...middlewares));

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);

export default store;
