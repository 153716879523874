import { apiRequest } from "../apiRequest";
import { ORDERS_ENDPOINT } from "../urls";
import moment from "moment";

export const fetchOrders = (query) => {
	let search_term = query && query.search_term;
	let from_date = query && query.from_date;
	let end_date = query && query.end_date;
	let status = query && query.status;
	let page = query && query.page;
	let salt = query && query.salt;
	let update = query && query.update;
	// let limit = 20;
	let limit = 3;

	return apiRequest(
		"GET",
		`${ORDERS_ENDPOINT}history?
		${search_term ? `&search=${search_term}` : ""}
		${
			from_date
				? `&date_order__gte=${moment(from_date).format("YYYY-MM-DD")}`
				: ""
		}
		${
			end_date
				? `&date_order__lte=${moment(end_date).format("YYYY-MM-DD")}`
				: ""
		}
		${status ? `&x_stored_order_status=${status}` : ""}
		${update ? `update=${update}` : ""}
		${salt ? `salt=${salt}` : ""}
		${page ? `&page=${page}` : ""}
		${limit ? `&limit=${limit}` : ""}`
	);
};

export const fetchOrderDetail = (id) => {
	return apiRequest("GET", `${ORDERS_ENDPOINT}${id}`, null);
};

//HH:mm:ss"