import { call, put, takeLatest, takeLeading } from "redux-saga/effects";
import { Creators, Types } from "../../redux/cart/actions";
import { Creators as ProductCreators } from "../../redux/products/actions";
import { Creators as AuthCreators } from "../../redux/auth/actions"
import { updateUser as updateUserApi } from "../../api/user"
import { sendOrder as sendOrderApi } from "../../api/cart";
import { checkPrices as checkingProductPricesApi } from "../../api/products"
import { getErrorMessageFromResponse, handleError } from "../../../util/helpers/handleError";
import { get } from "lodash";
import { refreshProductLocalCache, roundUpFloat } from "../../../util/helpers/commonHelper"
import { message } from "antd";

export function* sendOrder(actions) {
	try {
		const { order } = actions;
    // const orderProducts = order?.order_line
    //   .filter(it => !it.free_product)
    //   .map(it => {
    //     return { quantity: it.product_uom_qty, id: it.product_id }
    //   })

    // let productPriceErrorList = [];
    
    // try {
    //   //yield put(ProductCreators.resetCheckProductPrices())
    //   const productPriceResponse = yield call(checkingProductPricesApi, { order_products: orderProducts })
      
    //   productPriceErrorList = productPriceResponse?.data?.products
    //     .filter(it => {
    //       const orderData = (((order?.order_line || []).filter(orderData => orderData?.product_id === it.id) || [])[0]) || null
          
    //       // if(!orderData) {
    //       //   return false;
    //       // } else if(!it.new_price) {
    //       //   return false
    //       // } else {
    //       //   // console.log('old price', orderData.last_price, 'new price', it.new_price)
    //       //   // console.log('condition', orderData?.last_price !== it.new_price)
    //       //   return roundUpFloat(orderData?.last_price, 2) !== roundUpFloat(it.new_price, 2)
    //       // }
    //       if(!orderData) {
    //         return false;
    //       } else if(it.new_price) {
    //         return roundUpFloat(it.new_price, 2) !== roundUpFloat(orderData.last_price, 2)
    //       } else {
    //         return roundUpFloat(it.lst_price, 2) !== roundUpFloat(orderData.last_price, 2)
    //       }
    //     })
    //     .map(it => {
    //       const orderData = (((order?.order_line || []).filter(orderData => orderData?.product_id === it.id) || [])[0]) || null
          
    //       if(!orderData) {
    //         return it
    //       } else if(it.new_price) {
    //         if(roundUpFloat(it.new_price, 2) !== roundUpFloat(orderData.last_price, 2)) {
    //           return {
    //             ...it,
    //             new_price: it?.new_price,
    //             last_price: it?.lst_price
    //           }
    //         }
    //         return it
    //       } else {
    //         if(roundUpFloat(it.lst_price, 2) !== roundUpFloat(orderData.last_price)) {
    //           return {
    //             ...it,
    //             new_price: it.lst_price,
    //             last_price: orderData.last_price
    //           }
    //         }
    //         return it
    //       }
    //     }) || []

    //   yield put(ProductCreators.checkProductPricesSuccess(productPriceResponse?.data))
    // } catch(e) {
    //   yield put(ProductCreators.checkProductPricesFailure("Failed to check price-list"))
    // }

    
    // if(productPriceErrorList.length > 0) {
    //   refreshLocalCache()
    //   yield put(ProductCreators.cleanProducts())
    //   const errors = productPriceErrorList.map(it => {
    //     // return {
    //     //   product_id: it.id,
    //     //   last_price: it?.lst_price,
    //     //   product_uom_qty: it?.quantity,
    //     //   price_list_rule: true,
    //     //   errors: {price_changed: it?.new_price} 
    //     // }
    //     return {
    //       product_id: it.id,
    //       last_price: it?.last_price,
    //       new_price: it?.new_price,
    //       product_uom_qty: it?.quantity,
    //       price_list_rule: true,
    //       errors: {price_changed: it?.new_price} 
    //     }
    //   })
    //   yield put(Creators.sendOrderFailure(errors))
    // } else {
    //   const response = yield call(sendOrderApi, order);
		//   yield put(Creators.sendOrderSuccess(response?.data?.data));
    // }
      const response = yield call(sendOrderApi, order);
		  yield put(Creators.sendOrderSuccess(response?.data?.data));
	} catch (e) {

    if(e?.response?.data?.unaccepted_products) {
      const errData = e?.response?.data || {}
      const errors = (errData?.unaccepted_products || []).map(it => {
        return {
          product_id: it,
          errors: {
            unaccepted_products: true
          }
        }
      })
      yield put(Creators.sendOrderFailure(errors))
      yield put(ProductCreators.cleanProducts())
      refreshProductLocalCache()
    } else {
      if (get(e, "response.data.errors")) {
          yield put(Creators.sendOrderFailure(get(e, "response.data.errors")));
      } else {
        if (e.response && e.response.status === 500) {
          const errorMessage = handleError(e);
          yield put(Creators.sendOrderFailure('Failed to process payment', errorMessage));
        } else {
          handleError(e);
          yield put(Creators.sendOrderFailure(getErrorMessageFromResponse(e)));
        }
      }
    }
	}
}

export function* checkCartProductPrice(action) {
  const { order } = action
  const orderProducts = order?.order_line
  .filter(it => !it.free_product)
  .map(it => {
    return { quantity: it.product_uom_qty, id: it.product_id }
  })

  try {
    const response = yield call(checkingProductPricesApi, { order_products: orderProducts })
    yield put(Creators.checkCartProductPriceSuccess(response?.data))
    const productPriceErrorList = response?.data?.products
              .filter(it => {
                const orderData = (((order?.order_line || []).filter(orderData => orderData?.product_id === it.id) || [])[0]) || null

                if(!orderData) {
                  return false;
                } else if(it.new_price) {
                  return roundUpFloat(it.new_price, 2) !== roundUpFloat(orderData.last_price, 2)
                } else {
                  return roundUpFloat(it.lst_price, 2) !== roundUpFloat(orderData.last_price, 2)
                }
              })
              .map(it => {
                const orderData = (((order?.order_line || []).filter(orderData => orderData?.product_id === it.id) || [])[0]) || null
                
                if(!orderData) {
                  return it
                } else if(it.new_price) {
                  if(roundUpFloat(it.new_price, 2) !== roundUpFloat(orderData.last_price, 2)) {
                    return {
                      ...it,
                      new_price: it?.new_price,
                      last_price: it?.lst_price
                    }
                  }
                  return it
                } else {
                  if(roundUpFloat(it.lst_price, 2) !== roundUpFloat(orderData.last_price)) {
                    return {
                      ...it,
                      new_price: it.lst_price,
                      last_price: orderData.last_price
                    }
                  }
                  return it
                }
              }) || []

    if(productPriceErrorList.length > 0) {
      const priceChanges = productPriceErrorList.map(it => {
        return {
          product_id: it.id,
          last_price: it?.last_price,
          new_price: it?.new_price,
          product_uom_qty: it?.quantity,
          price_list_rule: true,
          errors: {price_changed: it?.new_price} 
        }
      })
      yield put(Creators.sendOrderFailure(priceChanges))
    }
  } catch(e) {
    yield put(Creators.checkCartProductPriceFailure(e))
    message.error("Price-list check failed")
  }
}

// export function* checkCartProductPrice(action) {
//   const { order } = action
//   const orderProducts = order?.order_line.map(it => {
//     return { quantity: it.product_uom_qty, id: it.product_id }
//   })

//   try {
//     const response = yield call(checkingProductPricesApi, { order_products: orderProducts })
//     console.log('response check pricelist', response.data)
//     yield put(Creators.checkCartProductPriceSuccess(response?.data))
//     let productPriceErrorList = response?.data?.products
//     .filter(it => it?.new_price)
//     .filter(it => {
//       const orderData = (((order?.order_line || []).filter(orderData => orderData?.product_id === it.id) || [])[0]) || null
      
//       if(!orderData) {
//         return false;
//       } else if(!it.new_price) {
//         return false
//       } else {
//         // console.log('old price', orderData.last_price, 'new price', it.new_price)
//         // console.log('condition', orderData?.last_price !== it.new_price)
//         return roundUpFloat(orderData?.last_price, 2) !== roundUpFloat(it.new_price, 2)
//       }
//     }) || []

//     if(productPriceErrorList.length > 0) {
//       const priceChanges = productPriceErrorList.map(it => {
//         return {
//           product_id: it.id,
//           last_price: it?.lst_price,
//           new_price: it?.new_price,
//           price_list_rule: true,
//           errors: {price_changed: it?.new_price} 
//         }
//       })
//       yield put(Creators.sendOrderFailure(priceChanges))
//     }
//   } catch(e) {
//     yield put(Creators.checkCartProductPriceFailure(e))
//   }
// }

export function* setOrderingFor(action) {
  const { employee, che } = action
  const payload = { ordering_for: che?.odoo_id }
  try {
    const response = yield call(updateUserApi, employee?.id, payload)
    console.log('set_order_for response', response)
    yield put(Creators.setOrderingForSuccess(che))
    yield put(AuthCreators.fetchOrderingForSuccess(che))
    localStorage.setItem("employee_ordering_for", JSON.stringify(che))
  } catch(e) {
    yield put(Creators.setOrderingForFailure(e))
  }
}

export function* watchSendOrder() {
	yield takeLeading(Types.SEND_ORDER, sendOrder);
}

export function* watchSetOrderingFor() {
  yield takeLatest(Types.SET_ORDERING_FOR, setOrderingFor)
}

export function* watchCheckCartProductPrice() {
  yield takeLatest(Types.CHECK_CART_PRODUCT_PRICE, checkCartProductPrice)
}