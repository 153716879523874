import { createReducer } from "reduxsauce";
import { getUserProfileFromStorage } from "../../../util/helpers/reusableFunctions";
import Types from "./actionTypes";

export const INITIAL_STATE = {
  activatingLogin: false,
  activateLogin: null,

  setPin: null,
  settingPin: false,

  isAuthenticating: false,
  auth: null,

  isRefreshingToken: false,

  isChangingPin: false,
  changedPin: null,

  isFetching: true,
  user: getUserProfileFromStorage() || null,

  error: null,

  isSavingDeviceToken: false,
  deviceTokenError: null,
  lastUpdated: Date.now(),

  savingDefaultPhoneNumber: false,
  saveDefaultNumberSuccess: false,

  activateQrcodeUrlStr: null,
  activatingQrcode: false,
  activateQrcodeSuccess: false,
  activatUserByQrcodeTries: 0,
  activateQrcodeKey: null,

  ordering_for_data: null,
  fetching_ordering_for: false,

  refreshingExpiredToken: false
};

export const activateLoginRequest = (state = INITIAL_STATE, action) => {
  return { ...state, activateLogin: null, activatingLogin: true, error: null };
};

export const activateLoginSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, activateLogin: action.responseData, activatingLogin: false };
};

export const activateLoginFailure = (state = INITIAL_STATE, action) => {
  return { ...state, activatingLogin: false, error: action.error };
};

export const setActivateLoginKey = (state = INITIAL_STATE, action) => {
  return { ...state, activateLogin: action.key, activatingLogin: false };
};

export const setPinRequest = (state = INITIAL_STATE, action) => {
  return { ...state, setPin: null, settingPin: true, error: null };
};

export const setPinSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, setPin: action.responseData, settingPin: false };
};

export const setPinFailure = (state = INITIAL_STATE, action) => {
  return { ...state, settingPin: false, error: action.error };
};

export const loginRequest = (state = INITIAL_STATE, action) => {
  return { ...state, auth: null, isAuthenticating: true, error: null };
};

export const loginSuccess = (state = INITIAL_STATE, action) => {
  const { responseData } = action
  return { ...state, auth: responseData, isAuthenticating: false, };
};

export const loginFailure = (state = INITIAL_STATE, action) => {
  return { ...state, isAuthenticating: false, error: action.error };
};



export const launchRequest = (state = INITIAL_STATE, action) => {
  return { ...state, auth: null, isAuthenticating: true, error: null, isRefreshingToken: true };
};

export const launchSuccess = (state = INITIAL_STATE, action) => {
  const { responseData } = action
  return { ...state, auth: responseData, isAuthenticating: false, isRefreshingToken: false };
};

export const launchFailure = (state = INITIAL_STATE, action) => {
  return { ...state, isAuthenticating: false, error: action.error, isRefreshingToken: false };
};


export const refreshTokenRequest = (state = INITIAL_STATE, action) => {
  return { ...state, isRefreshingToken: true };
};

export const refreshTokenSuccess = (state = INITIAL_STATE, action) => {
  return { ...state,
    isRefreshingToken: false };
};

export const refreshTokenFailure = (state = INITIAL_STATE, action) => {
  return { ...state, isRefreshingToken: false };
};

export const changePinRequest = (state = INITIAL_STATE, action) => {
  return { ...state, changedPin: null, isChangingPin: true, error: null };
};

export const changePinSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, changedPin: action.responseData, isChangingPin: false };
};

export const changePinFailure = (state = INITIAL_STATE, action) => {
  return { ...state, isChangingPin: false, error: action.error };
};

export const getUserDetails = (state = INITIAL_STATE, action) => {
  return { ...state, isFetching: true, error: null };
};

export const getUserDetailsSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, user: action.responseData, isFetching: false, lastUpdated: Date.now() };
};

export const getUserDetailsFailure = (state = INITIAL_STATE, action) => {
  return { ...state, isFetching: false, error: action.error };
};

export const getUserDetailsWithSalt = (state = INITIAL_STATE, action) => {
  return { ...state, isFetching: true, error: null };
};

export const getUserDetailsWithSaltSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, user: action.responseData, isFetching: false, lastUpdated: Date.now() };
};

export const getUserDetailsWithSaltFailure = (state = INITIAL_STATE, action) => {
  return { ...state, isFetching: false, error: action.error };
};

export const logout = (state = INITIAL_STATE, action) => {
  return { ...state, error: false, user: null };
};

export const saveDeviceToken = (state = INITIAL_STATE, action) => {
  return { ...state, isSavingDeviceToken: true, deviceTokenError: null };
};

export const saveDeviceTokenSuccess = (state = INITIAL_STATE, action) => {
  return { ...state, isSavingDeviceToken: false };
};

export const saveDeviceTokenFailure = (state = INITIAL_STATE, action) => {
  return { ...state, isSavingDeviceToken: false, deviceTokenError: action.error };
};

export const saveDefaultPhoneNumber = (state = INITIAL_STATE, action) => {
  return {
    ...state, 
    error: null,
    savingDefaultPhoneNumber: true,
    saveDefaultPhneNumberSuccess: false,
  }
}

export const saveDefaultPhoneNumberSuccess = (state = INITIAL_STATE, action) => {
  const { default_phone_number } = action.responseData
  return {
    ...state, 
    error: null,
    savingDefaultPhoneNumber: false,
    saveDefaultNumberSuccess: true,
    user: {...state.user, default_phone_number: default_phone_number}
  }
}

export const saveDefaultPhoneNumberFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    savingDefaultNumber: false,
    savingDefaultPhoneNumber: false,
    error: action.error
  }
}

export const activateUserByQrcode = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    activateQrcodeUrlStr: null,
    activatingQrcode: true,
    activateQrcodeSuccess: false
  }
}

export const activateUserByQrcodeSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    activateQrcodeUrlStr: action.responseData,
    activatingQrcode: false,
    activateQrcodeSuccess: true,
    activatUserByQrcodeTries: 1
  }
}

export const activateUserByQrcodeFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    activateQrcodeUrlStr: null,
    activatingQrcode: false,
    activateQrcodeSuccess: false,
    error: action?.error,
    activatUserByQrcodeTries: 1
  }
}

export const fetchOrderingFor = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ordering_for_data: null,
    fetching_ordering_for: true
  }
}

export const fetchOrderingForSuccess = (state = INITIAL_STATE, action) => {
  const che = action.responseData
  return {
    ...state,
    ordering_for_data: che,
    fetching_ordering_for: false
  }
}

export const fetchOrderingForFailure = (state = INITIAL_STATE, action) => {
  const { error } = state
  return {
    ...state,
    ordering_for_data: null,
    fetching_ordering_for: false,
    error: error
  }
}

export const resetQrcodeScan = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    activateQrcodeUrlStr: null,
    activatingQrcode: false,
    activateQrcodeSuccess: false,
    error: null,
    activatUserByQrcodeTries: 0,
  }
}

export const refreshExpiredToken = (state = INITIAL_STATE, action) => {
  return { ...state, refreshingExpiredToken: true };
};

export const refreshExpiredTokenSuccess = (state = INITIAL_STATE, action) => {
  return { ...state,
    refreshingExpiredToken: false };
};

export const refreshExpiredTokenFailure = (state = INITIAL_STATE, action) => {
  return { ...state, refreshingExpiredToken: false };
};

export const setOrderingForData = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ordering_for_data: action?.che
  }
}

export const HANDLERS = {
  [Types.ACTIVATE_LOGIN_REQUEST]: activateLoginRequest,
  [Types.ACTIVATE_LOGIN_SUCCESS]: activateLoginSuccess,
  [Types.ACTIVATE_LOGIN_FAILURE]: activateLoginFailure,
  [Types.SET_ACTIVATE_LOGIN_KEY]: setActivateLoginKey,

  [Types.SET_PIN_REQUEST]: setPinRequest,
  [Types.SET_PIN_SUCCESS]: setPinSuccess,
  [Types.SET_PIN_FAILURE]: setPinFailure,

  [Types.LOGIN_REQUEST]: loginRequest,
  [Types.LOGIN_SUCCESS]: loginSuccess,
  [Types.LOGIN_FAILURE]: loginFailure,

  [Types.LAUNCH_REQUEST]: launchRequest,
  [Types.LAUNCH_SUCCESS]: launchSuccess,
  [Types.LAUNCH_FAILURE]: launchFailure,

  [Types.REFRESH_TOKEN_REQUEST]: refreshTokenRequest,
  [Types.REFRESH_TOKEN_SUCCESS]: refreshTokenSuccess,
  [Types.REFRESH_TOKEN_FAILURE]: refreshTokenFailure,

  [Types.REFRESH_EXPIRED_TOKEN]: refreshExpiredToken,
  [Types.REFRESH_EXPIRED_TOKEN_SUCCESS]: refreshExpiredTokenSuccess,
  [Types.REFRESH_EXPIRED_TOKEN_FAILURE]: refreshExpiredTokenFailure,

  [Types.CHANGE_PIN_REQUEST]: changePinRequest,
  [Types.CHANGE_PIN_SUCCESS]: changePinSuccess,
  [Types.CHANGE_PIN_FAILURE]: changePinFailure,

  [Types.GET_USER_DETAILS]: getUserDetails,
  [Types.GET_USER_DETAILS_SUCCESS]: getUserDetailsSuccess,
  [Types.GET_USER_DETAILS_FAILURE]: getUserDetailsFailure,

  [Types.GET_USER_DETAILS_WITH_SALT]: getUserDetailsWithSalt,
  [Types.GET_USER_DETAILS_WITH_SALT_SUCCESS]: getUserDetailsWithSaltSuccess,
  [Types.GET_USER_DETAILS_WITH_SALT_FAILURE]: getUserDetailsWithSaltFailure,

  [Types.SAVE_DEVICE_TOKEN]: saveDeviceToken,
  [Types.SAVE_DEVICE_TOKEN_SUCCESS]: saveDeviceTokenSuccess,
  [Types.SAVE_DEVICE_TOKEN_FAILURE]: saveDeviceTokenFailure,

  [Types.SAVE_DEFAULT_PHONE_NUMBER]: saveDefaultPhoneNumber,
  [Types.SAVE_DEFAULT_PHONE_NUMBER_SUCCESS]: saveDefaultPhoneNumberSuccess,
  [Types.SAVE_DEFAULT_PHONE_NUMBER_FAILURE]: saveDefaultPhoneNumberFailure,

  [Types.ACTIVATE_USER_BY_QRCODE]: activateUserByQrcode,
  [Types.ACTIVATE_USER_BY_QRCODE_SUCCESS]: activateUserByQrcodeSuccess,
  [Types.ACTIVATE_USER_BY_QRCODE_FAILURE]: activateUserByQrcodeFailure,

  [Types.FETCH_ORDERING_FOR]: fetchOrderingFor,
  [Types.FETCH_ORDERING_FOR_SUCCESS]: fetchOrderingForSuccess,
  [Types.FETCH_ORDERING_FOR_FAILURE]: fetchOrderingForFailure,

  [Types.SET_ORDERING_FOR_DATA]: setOrderingForData,

  [Types.RESET_QRCODE_SCAN]: resetQrcodeScan,

  [Types.LOGOUT]: logout,
};

export default createReducer(INITIAL_STATE, HANDLERS);
