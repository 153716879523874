import { createTypes } from "reduxsauce";

export default createTypes(
	`
    FETCH_CREDIT_BALANCE
    FETCH_CREDIT_BALANCE_SUCCESS
    FETCH_CREDIT_BALANCE_FAILURE
    FETCH_CREDIT_BALANCE_SALT

    FETCH_INVOICES
    FETCH_INVOICES_SUCCESS
    FETCH_INVOICES_FAILURE

    RESET_INVOICES

    FETCH_OPEN_PAYMENTS
    FETCH_OPEN_PAYMENTS_SUCCESS
    FETCH_OPEN_PAYMENTS_FAILURE
    RESET_OPEN_PAYMENTS

    SET_TAB

  `,
	{}
);