import { createTypes } from "reduxsauce";

export default createTypes(
	`
  FETCH_USERS
  FETCH_USERS_SUCCESS
  FETCH_USERS_FAILURE

  UPDATE_USER
  UPDATE_USER_SUCCESS
  UPDATE_USER_FAILURE

  RESET_USER
`,
	{}
);
