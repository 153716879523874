import { createReducer } from "reduxsauce";
import Types from "./actionTypes";


export const INITIAL_STATE = {
  fetchingProduct: false,
  products: [],
  hasMoreProducts: false,
  fetchingMore: false,

  error: null,
  fetchingCategory: false,
  categories: [],

  syncingProducts: false,
  syncProductsSuccess: false,

  checkingProductPrices: false,
  checkProductPricesSuccess: false,
  productPricesData: null,

  checkingProductListPrice: false,
  productListPriceData: {},

  productPriceChangeError: false,
  productPriceChangeList: {},

  toggle: false,
};

export const getProductListRequest = (state = INITIAL_STATE, action) => {
  return { ...state, fetchingProduct: true, error: null };
};

export const getProductListSuccess = (state = INITIAL_STATE, action) => {
  let data;
  if (state.products?.results) {
    data = state.products;
    data.next = action.responseData.next;
    data.results.push(...action.responseData.results);
  } else {
    data = action.responseData;
  }
  return {
    ...state,
    products: data,
    hasMoreProducts: !!data.next,
    fetchingProduct: false,
  };
};

export const getProductListFailure = (state = INITIAL_STATE, action) => {
  return { ...state, fetchingProduct: false, error: action.error };
};

export const refresh = (state = INITIAL_STATE, action) => {
  return { ...state, toggle: !state.toggle };
};

export const getProductCategoryRequest = (state = INITIAL_STATE, action) => {
  return { ...state, fetchingCategory: true, error: null }
}
 
export const getProductCategorySuccess = (state = INITIAL_STATE, action) => {
    return { ...state, categories: action.responseData, fetchingCategory: false }
}
 
export const getProductCategoryFailure = (state = INITIAL_STATE, action) => {
  return { ...state, fetchingCategory: false, error: action.error }
}

export const cleanProduct = (state = INITIAL_STATE, action) => {
  return { ...state, products: [], hasMoreProducts: false };
};

export const syncProducts = (state = INITIAL_STATE, action) => {
  return {...state, syncingProducts: true}
}

export const syncProductsSuccess = (state = INITIAL_STATE, action) => {
  return {...state, syncingProducts: false, syncProductsSuccess: true}
}

export const syncProductsFailure = (state = INITIAL_STATE, action) => {
  return {...state, syncingProducts: false, syncProductsSuccess: false}
}

export const refreshProductListCache = (state = INITIAL_STATE, action) => {
  return {
    ...state
  }
}

export const checkProductPrices = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    checkingProductPrices: true,
    checkProductPricesSuccess: false,
    productPricesData: null
  }
}

export const checkProductPricesSuccess = (state = INITIAL_STATE, action) => {
  // const productErrorsList = (responseData.products || [])
  //                             .filter(it => it.new_price)

  return {
    ...state,
    checkingProductPrices: false,
    checkProductPricesSuccess: true,
    // productPricesData: responseData,
    // productPriceChangeError: productErrorsList.length > 0,
    // productPriceChangeList: productErrorsList
  }
}

export const checkProductPricesFailure = (state = INITIAL_STATE, action) => {
  const { error } = action
  return {
    ...state,
    checkingProductPrices: false,
    checkProductPricesSuccess: false,
    productPricesData: null,
    error
  }
}


export const checkProductListPrice = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    checkingProductListPrice: true,
  }
}

export const checkProductListPriceSuccess = (state = INITIAL_STATE, action) => {
  const { responseData } = action
  const data = (responseData.products || [])
                              .filter(it => it.new_price)
  let priceData = { ...state.productListPriceData }                            
  for(let i = 0; i < data.length; i++) {
    const dd = data[i]
    priceData[dd.id] = { new_price: dd.new_price, last_price: dd.lst_price }
  }
  return {
    ...state,
    checkingProductListPrice: false,
    productListPriceData: priceData,
    products: {...state.products, results: state.products.results.map(it => {
      if(priceData[it.id]) {
        //return { ...it, lst_price: priceData[it.id]['new_price'], price_list_rule: true }
        return { ...it, lst_price: priceData[it.id]['new_price'], price_list_rule: true}
      } else {
        return {...it}
      }
    })} 
  }
}

export const checkProductListPriceFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    checkingProductListPrice: false,
  }
}

export const resetCheckProductPrices = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    checkingProductPrices: false,
    checkProductPricesSuccess: false,
    productPricesData: null,
    productPriceChangeError: false,
    productPriceChangeList: []
  }
}

export const HANDLERS = {
  [Types.GET_PRODUCT_LIST_REQUEST]: getProductListRequest,
  [Types.GET_PRODUCT_LIST_SUCCESS]: getProductListSuccess,
  [Types.GET_PRODUCT_LIST_FAILURE]: getProductListFailure,
  [Types.GET_PRODUCT_CATEGORY_REQUEST]: getProductCategoryRequest,
  [Types.GET_PRODUCT_CATEGORY_SUCCESS]: getProductCategorySuccess,
  [Types.GET_PRODUCT_CATEGORY_FAILURE]: getProductCategoryFailure,

  [Types.SYNC_PRODUCTS]: syncProducts,
  [Types.SYNC_PRODUCTS_SUCCESS]: syncProductsSuccess,
  [Types.SYNC_PRODUCTS_FAILURE]: syncProductsFailure,

  [Types.REFRESH_PRODUCT_LIST_CACHE]: refreshProductListCache,

  [Types.CHECK_PRODUCT_PRICES]: checkProductPrices,
  [Types.CHECK_PRODUCT_PRICES_SUCCESS]: checkProductPricesSuccess,
  [Types.CHECK_PRODUCT_PRICES_FAILURE]: checkProductPricesFailure,

  [Types.CHECK_PRODUCT_LIST_PRICE]: checkProductListPrice,
  [Types.CHECK_PRODUCT_LIST_PRICE_SUCCESS]: checkProductListPriceSuccess,
  [Types.CHECK_PRODUCT_LIST_PRICE_FAILURE]: checkProductListPriceFailure,

  [Types.RESET_CHECK_PRODUCT_PRICES]: resetCheckProductPrices,

  [Types.REFRESH]: refresh,
  [Types.CLEAN_PRODUCTS]: cleanProduct,
};

export default createReducer(INITIAL_STATE, HANDLERS);
