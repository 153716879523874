import { createActions } from "reduxsauce";

export const { Types, Creators } = createActions(
	{
		addItem: ["item", "action", "quantity"],

		addFreeProducts: ["products"],

		incrementItem: ["id"],

		decrementItem: ["id"],

		removeItem: ["id"],

		setCurrency: null,

		sendOrder: ["order"],
		sendOrderSuccess: ["responseData"],
		sendOrderFailure: ["error"],

    checkCartProductPrice: ["order"],
    checkCartProductPriceSuccess: ["responseData"],
    checkCartProductPriceFailure: ["error"],

    cleanUnacceptedProductsFromCart: null,


    updateChe: ["che"],

		resetOrder: null,

    setOrderingFor: ["employee","che"],
    setOrderingForSuccess: ["data"],
    setOrderingForFailure: ["error"],

    resetOrderError: null
	},
	{}
);
