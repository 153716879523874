import { message } from "antd";

const ErrorMessage = (msg) => {
  message.error(msg, 10);
};

export const getErrorMessage = (data) => {
  return Object.values(data).join(", ");
};

export const handleError = (error) => {
  const errorMsg = getErrorMessageFromResponse(error)
  ErrorMessage(errorMsg);
  return errorMsg;
};

export const getError = (error) => {
  const errorMsg = getErrorMessageFromResponse(error)
  return errorMsg;
}

export const getErrorMessageFromResponse = (error) => {
  let errorMsg;
  if (error?.response === undefined) {
    errorMsg = "No internet connection, cannot reach server / Pas de connexion Internet, impossible d'accéder au serveur";
  } else if (error?.response && error?.response?.status === 500) {
    errorMsg = "A server error occured";
  } else if (error?.response && error?.response?.status < 500) {
    errorMsg = getErrorMessage(error?.response?.data);
  } else {
    errorMsg = error?.response?.data?.error || error?.response?.data?.errors[0] || "Request timeout"
  }
  return errorMsg;
}
