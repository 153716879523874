import React, { useEffect } from 'react';
import { Spin } from 'antd';
import { connect } from 'react-redux';

import { Creators } from '../../../services/redux/auth/actions'

const SyncProducts = ({ getUserDetails, isLoading }) => {

    const syncProductsAndRedirect = async () => {
        if ('caches' in window) {
            await caches.delete('endpoints-cache')
        }
        window.location = "/dashboard"
    }

    useEffect(() => {
        getUserDetails(JSON.parse(localStorage.getItem("he-che-user")).id);
        // eslint-disable-next-line
    }, [])

    if (!isLoading) {
        syncProductsAndRedirect()
    }
    return (
        <div className="load-more">
            <Spin size="large" tip="Updating Products..." />
        </div>
    )
}

const mapStateToProps = state => {
    return {
        isLoading: state.auth.isFetching,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getUserDetails: (id) => {
            dispatch(Creators.getUserDetails(id))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SyncProducts);