import { createActions } from "reduxsauce";

export const { Types, Creators } = createActions(
	{
		getProductListRequest: ["query"],
		getProductListSuccess: ["responseData"],
		getProductListFailure: ["error"],

		getProductCategoryRequest: [],
  	getProductCategorySuccess: ['responseData'],
  	getProductCategoryFailure: ['error'],
		refresh: null,

		syncProducts: ["query"],
		syncProductsSuccess: null,
		syncProductsFailure: ["error"],

    refreshProductListCache: null,

    checkProductPrices: ["payload"],
    checkProductPricesSuccess: ["responseData"],
    checkProductPricesFailure: ["error"],

    checkProductListPrice: ["payload"],
    checkProductListPriceSuccess: ["responseData"],
    checkProductListPriceFailure: ["error"],

    

    resetCheckProductPrices: null,
    resetCheckProductListPrice: null,

		cleanProducts: null
	},
	{}
);
