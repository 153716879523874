import axios from 'axios';
import { BASE_URL } from './urls'; 
import { history } from '../../util/helpers/browserHistory'
import { getUserFromLocalStorage } from '../../util/helpers/commonHelper';
// import store from '../../store';
// import { Creators } from '../../services/redux/auth/actions';


export const getToken = () => {
  let user = JSON.parse(localStorage.getItem('auth'));
  let token = user && user.access;
  return token;
}

export const getRefreshToken = () => {
  let user = JSON.parse(localStorage.getItem('auth'));
  if (user) {
    return user.refresh
  }
}

const getUserCountry = () => {
  let user = JSON.parse(localStorage.getItem('he-che-user'));
  if (user && user.country) return user.country.id
  return ''
}

export const axiosInstance = () => {
  const AUTH_TOKEN = getToken();
  const userData = getUserFromLocalStorage()
  let LANGUAGE = userData ? userData?.country?.language_code : "en"
  const instance = axios.create({
    baseURL: BASE_URL,
    // headers: {
    //   Accept: 'application/json',
    //   ...(AUTH_TOKEN ? {Authorization: `Bearer ${AUTH_TOKEN}`} : {}),
    //   'x_company_id': getUserCountry()
    // }
  });

  instance.interceptors.request.use(request => {
    if(request.url.includes('notokenrequired')) {
      request.headers = {
        Accept: 'application/json',
        'x_company_id': getUserCountry(),
        'Accept-Language': LANGUAGE
      }
      request.url = request.url.split('?')[0]
    } else if(request.url.includes('/auth/qr_code')) {
      request.headers = {
        Accept: 'application/json',
        'x_company_id': getUserCountry(),
        'Accept-Language': LANGUAGE
      }
    } else {
      request.headers = {
        Accept: 'application/json',
        ...(AUTH_TOKEN ? {Authorization: `Bearer ${AUTH_TOKEN}`} : {}),
        'x_company_id': getUserCountry(),
        'Accept-Language': LANGUAGE
      }
    }
    return request
  })

  instance.interceptors.response.use(
    response => {
      return response
    },
    error => {
      const status = error?.response?.status

      if(status === 401) {
        const refresh_token = getRefreshToken();
        if (refresh_token) {
          if(error?.response?.config?.url.includes('/auth/refresh')) {
            //window.location.href = "/login"
            history.push("/login")
            
          } else {
            //window.location.href = "/refresh-token"
            history.push("/refresh-token")
          }
        } else {
          //window.location.href = "/login"
          history.push("/login")
          //return Promise.reject(error)
        }
      } else if((status === 400) && error?.response?.config?.url.includes('/auth/refresh')) {
        history.push("/login")
      } else {
        return Promise.reject(error);
      }
    }
  )

  return instance;
}