import { all } from "redux-saga/effects";
import {
	watchActivateLogin,
	watchSetPin,
	watchLogin,
	watchChangePin,
	watchGetUserDetails,
	watchSaveDeviceToken,
	watchRefreshToken,
	watchGetUserDetailsWithSalt,
	watchSaveDefaultPhoneNumber,
  watchActivateUserByQrcode,
  watchRefreshExpiredToken,
  watchLaunch,
  watchFetchOrderingFor,
} from "./auth";
import { watchGetProducts, watchGetProductCategory, watchSyncProducts, watchRefreshProductListCache, watchCheckProductPrices, watchGetProductsSuccess } from "./products";
import { watchCheckCartProductPrice, watchSendOrder, watchSetOrderingFor } from "./cart";
import { watchCancelFakePayment, watchCheckOrderPaymentStatus, watchCheckTransactionStatus, watchMakeFakePayment, watchMakePayment } from "./payments";
import { watchFetchOrderDetails, watchFetchOrders } from "./orders";
import { watchFetchCreditBalance, watchFetchInvoices, watchFetchOpenPayments, watchSetTab } from "./accounting"
import { watchFetchUsers, watchUpdateUser } from "./users";

export default function* rootSaga() {
	yield all([
		watchActivateLogin(),
    watchActivateUserByQrcode(),
		watchSetPin(),
		watchLogin(),
    watchLaunch(),
		watchChangePin(),
		watchGetUserDetails(),
		watchGetProducts(),
		watchGetProductCategory(),
    watchCheckProductPrices(),
    watchGetProductsSuccess(),
		watchSendOrder(),
    watchCheckCartProductPrice(),
    watchSetOrderingFor(),
		watchMakePayment(),
		watchMakeFakePayment(),
    watchCheckOrderPaymentStatus(),
    watchCheckTransactionStatus(),
		watchCancelFakePayment(),
		watchFetchOrders(),
		watchFetchOrderDetails(),
		watchSaveDeviceToken(),
		watchRefreshToken(),
    watchRefreshExpiredToken(),
		watchSyncProducts(),
    watchRefreshProductListCache(),
		watchGetUserDetailsWithSalt(),
		watchFetchCreditBalance(),
		watchFetchInvoices(),
    watchFetchOpenPayments(),
		watchSaveDefaultPhoneNumber(),
    watchSetTab(),
    watchFetchUsers(),
    watchUpdateUser(),
    watchFetchOrderingFor(),
	]);
}
