import { message } from "antd";

export async function filterProducts(categoryId, searchTerm) {
  let result = [];
  try {
    const cache = await caches.open('endpoints-cache')
    const keys = await cache.keys()
    const productsKeys = keys.filter(item => item.url.indexOf('/api/products/?') !== -1)
    
    for(let i=0; i < productsKeys.length ; i++) {
      const myResponse = await cache.matchAll(productsKeys[i]).then(res => res[0].json())
      result.push(...myResponse.results)
    }
    const filteredList = (result || []).filter(item => {
      if(categoryId && !searchTerm) {
        return item.category.odoo_id === categoryId
      } else if(!categoryId && searchTerm) {
        return item.name?.toLowerCase().includes(searchTerm.toLowerCase())
      } else if(!categoryId && !searchTerm) {
        return item
      } else {
        return item.category.odoo_id === categoryId && item.name?.toLowerCase().includes(searchTerm.toLowerCase())
      }
    }).filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i)
    //return {results: filteredList, next: null, count: result.length, previous: null}
    return {results: filteredList.sort((a,b)=> a.name.localeCompare(b.name)), next: null, count: result.length, previous: null}
  } catch (error) {
    return {results: [], next: null, count: result.length, previous: null}
  } 
}

export async function clearEndpointCache() {
  if('caches' in window) {
    await caches.delete('endpoints-cache')
  } else {
    message.error('Browser does not support cache storage!')
  }
}

export function getPhoneNumberFromLocalStore() {
  try {
    return localStorage.getItem("phone_number");
  } catch (error) {
    return null;
  }
}