import { combineReducers } from "redux";
import auth from "./services/redux/auth/reducers";
import pagination from "./services/redux/pagination/reducers";
import products from "./services/redux/products/reducers";
import cart from "./services/redux/cart/reducers";
import payment from "./services/redux/payments/reducers";
import orders from "./services/redux/orders/reducers";
import clearCache from "./util/helpers/clearCache";
import accounting from "./services/redux/accounting/reducers"
import telnyx from "./services/redux/telnyx/reducers"
import users from "./services/redux/users/reducers"

const allReducers = combineReducers({
	auth,
	pagination,
	products,
	cart,
	payment,
	orders,
	accounting,
	telnyx,
  users
});

const rootReducer = (state, action) => {
	if (action.type === "LOGOUT") {
		clearCache();
		state = undefined;
	}

	return allReducers(state, action);
};

export default rootReducer;
