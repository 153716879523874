import { createReducer } from "reduxsauce";
import Types from "./actionTypes";

export const INITIAL_STATE = {
  credit_balance: null,
  loading: false,
  error: null,
  invoices: [],
  totalCount: 0,
  hasMore: false,
  nextPage: null,

  openPayments: [],
  loadingOpenPayments: false,
  openPaymentsError: null,
  openPaymentsTotalCount: 0,
  hasMoreOpenPayments: false,
  openPaymentsNextPage: null,

  tab: localStorage.getItem('ACCOUNTING_TAB') || 1
};

const fetchCreditBalance = (state = INITIAL_STATE, actions) => {
  return {
    state,
    loading: true,
    error: null
  }
};

const fetchCreditBalanceSalt = (state = INITIAL_STATE, actions) => {
  return {
    ...state,
    loading: true,
    error: null
  }
};

const fetchCreditBalanceSuccess = (state = INITIAL_STATE, actions) => {
  const { responseData } = actions
  return {
    ...state,
    loading: false,
    error: null,
    credit_balance: responseData?.credit_balance,
  }
};

const fetchCreditBalanceFailure = (state = INITIAL_STATE, actions) => {
  const { error } = actions
  return {
    ...state,
    loading: false,
    error: error,
    credit_balance: null
  }
};

const fetchInvoices = (state=INITIAL_STATE, actions) => {
  return {
    ...state,
    loading: true,
    error: null
  }
}

const fetchInvoicesSuccess = (state=INITIAL_STATE, action) => {
  const { totalCount, hasMore, nextPage, results } = action
  return {
    ...state,
    invoices: state.invoices.concat(results),
    loading: false,
    error: null,
    totalCount: totalCount,
    hasMore: hasMore,
    nextPage: nextPage,
  }
}

const fetchInvoicesFailure = (state=INITIAL_STATE, actions) => {
  const { error } = actions
  return {
    ...state,
    loading: false,
    error: error,
    hasMore: false
  }
}

const resetInvoices = (state=INITIAL_STATE, actions) => {
  return {
    ...state,
    credit_balance: null,
    loading: false,
    error: null,
    invoices: [],
    totalCount: 0,
    hasMore: false,
    nextPage: null,
  }
}

//OPEN PAYMENTS

const fetchOpenPayments = (state=INITIAL_STATE, actions) => {
  return {
    ...state,
    loadingOpenPayments: true,
    openPaymentsError: null
  }
}

const fetchOpenPaymentsSuccess = (state=INITIAL_STATE, action) => {
  const { totalCount, hasMore, nextPage, results } = action
  return {
    ...state,
    openPayments: state.openPayments.concat(results),
    loadingOpenPayments: false,
    openPaymentsError: null,
    openPaymentsTotalCount: totalCount,
    openPaymentsHasMore: hasMore,
    openPaymentsNextPage: nextPage,
  }
}

const fetchOpenPaymentsFailure = (state=INITIAL_STATE, actions) => {
  const { error } = actions
  return {
    ...state,
    loadingOpenPayments: false,
    openPaymentsError: error,
    openPaymentsHasMore: false
  }
}

const setTab = (state = INITIAL_STATE, actions) => {
  const { tab } = actions
  return {
    ...state,
    tab 
  }
}

const resetOpenPayments = (state=INITIAL_STATE, actions) => {
  return {
    ...state,
    loadingOpenPayments: false,
    openPaymentsError: null,
    openPayments: [],
    openPaymentTotalCount: 0,
    openPaymentsHasMore: false,
    openPaymentsNextPage: null,
  }
}



export const HANDLERS = {
  [Types.RESET_INVOICES]: resetInvoices,
  [Types.FETCH_CREDIT_BALANCE]: fetchCreditBalance,
  [Types.FETCH_CREDIT_BALANCE_SUCCESS]: fetchCreditBalanceSuccess,
  [Types.FETCH_CREDIT_BALANCE_FAILURE]: fetchCreditBalanceFailure,
  [Types.FETCH_CREDIT_BALANCE_SALT]: fetchCreditBalanceSalt,
  [Types.FETCH_INVOICES]: fetchInvoices,
  [Types.FETCH_INVOICES_SUCCESS]: fetchInvoicesSuccess,
  [Types.FETCH_INVOICES_FAILURE]: fetchInvoicesFailure,

  [Types.FETCH_OPEN_PAYMENTS]: fetchOpenPayments,
  [Types.FETCH_OPEN_PAYMENTS_SUCCESS]: fetchOpenPaymentsSuccess,
  [Types.FETCH_OPEN_PAYMENTS_FAILURE]: fetchOpenPaymentsFailure,
  [Types.RESET_OPEN_PAYMENTS]: resetOpenPayments,
  [Types.SET_TAB]: setTab
};

export default createReducer(INITIAL_STATE, HANDLERS);