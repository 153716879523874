import { notification, message } from "antd";
import Rollbar from 'rollbar'

export const messageConfig = () => {
  return message.config({
    duration: 2,
    top: 96
  });
}

export const rollbarConfig = {
  accessToken: process.env.RECT_APP_ROLLBAR_ENV === "production" ? process.env.REACT_APP_ROLLBAR_CLIENT_POST_ACCESS_TOKEN : "",
  environment: process.env.RECT_APP_ROLLBAR_ENV === "production" ? process.env.RECT_APP_ROLLBAR_ENV : "",
};

export const rollbar = new Rollbar(rollbarConfig)

export const notificationConfig = () => {
  return notification.config({
    duration: 1,
    placement: 'bottomRight'
  });
}

export const momoChannels = [
  "momo", "airtel","ihela","mpesa"
]

export const PAYMENT_WAIT_TIME = 180;

export const appStyleConfig = {
  he_green: "#30994B",
  he_white: "#fff"
}

export const IMGAE_PREFIX = "new-upgrade";