import { apiRequest } from "../apiRequest";
import {
	ACTIVATE_LOGIN_ENDPOINT,
	SET_PIN_ENDPOINT,
	LOGIN_ENDPOINT,
	CHANGE_PIN_ENDPOINT,
	USER_PROFILE_ENDPOINT,
	SAVE_DEVICE_TOKEN,
	REFRESH_TOKEN,
  LAUNCH_ENDPOINT
} from "../urls";

export const activateLogin = (data) => {
	return apiRequest("POST", ACTIVATE_LOGIN_ENDPOINT, data);
};

export const setPin = (data) => {
	return apiRequest("POST", SET_PIN_ENDPOINT, data);
};

export const login = (data) => {
	return apiRequest("POST", LOGIN_ENDPOINT, data);
};

export const launch = (data) => {
	return apiRequest("POST", LAUNCH_ENDPOINT, data);
};

export const changePin = (data) => {
	return apiRequest("PATCH", CHANGE_PIN_ENDPOINT, data);
};

export const getUserDetails = () => {
	return apiRequest("GET", USER_PROFILE_ENDPOINT);
};

export const getUserDetailsWithSalt = () => {
	return apiRequest("GET", `${USER_PROFILE_ENDPOINT}?salt=${Math.floor(Date.now() / 1000)}`);
};

export const saveDeviceToken = (data) => {
	return apiRequest("POST", SAVE_DEVICE_TOKEN, data);
};

export const refreshToken = (data) => {
	return apiRequest("POST", REFRESH_TOKEN, data);
};

export const refreshExpiredToken = (data) => {
	return apiRequest("POST", `${REFRESH_TOKEN}?notokenrequired=true`, data);
};

export const saveDefaultPhoneNumber = (id, payload) => {
	return apiRequest("PATCH", `/user/ches/${id}`, payload)
}

export const activateByQrcode = (payload) => {
  const { uid, authCode } = payload
  return apiRequest("GET", `/user/auth/qr_code?auth_code=${authCode}&uid=${uid}`)
}
