import { LOCALES } from "./locales";

export const messages = {
  [LOCALES.ENGLISH]: {
    SEARCH: "Search",
    CREDIT_BALANCE: "CREDIT BALANCE",
    OPEN_PAYMENTS: "OPEN PAYMENTS",
    UPDATED: "UPDATED",
    ACCOUNT: "Account",
    ALL_ORDERS: "All Orders",
    CHANGE_PIN: "Change Pin",
    LOGOUT: "Logout",
    LOADING: "Loading",
    PAYMENT_SERVICE_LOADING: "Payment service loading",
    ALL: "All",
    IN_YOUR_CART: "In Your Cart",
    CUSTOMER: "Customer",
    TOTAL: "Total",
    YOUR_CART_IS_EMPTY: "Your cart is empty",
    YOU_CURRENTLY_HAVE_NO_ITEM_ADDED: "You currently have no item added to your cart",
    GO_TO_PRODUCT_PAGE: "Go to Product Page",
    FREE: "FREE",
    PAY_WITH_CASH: "Pay with cash",
    PAY_WITH_MOBILE_MONEY: "Pay with mobile money",
    TEST_PAYMENT: "Test payment",
    PLEASE_SELECT_PAYMENT_METHOD: "Please select a payment method",
    PAY: "Pay",
    REFRESH_ALL_ORDERS: "Refresh all orders",
    CONFIRMED: "Confirmed",
    TO_BE_CONFIRMED: "To be confirmed",
    LEFT_WAREHOUSE: "Left warehouse",
    NOT_DELIVERED: "Not delivered",
    CANCELLED: "Cancelled",
    STATUS: "Status",
    START_DATE: "Start date",
    END_DATE: "End date",
    NO_INTERNET_CONNECTION: "No internet connection. Please connect to the internet first before completing the payment of an order",
    GO_BACK: "Go back",
    OLD_PIN: "Old PIN",
    ENTER_YOUR_NEW_PIN: "Enter your new PIN",
    CONFIRM_YOUR_NEW_PIN: "Confirmer le code PIN",
    ENTER_YOUR_OLD_PIN: "Enter your old PIN",
    PRICES_CHANGED_FOR: "Prices changed for",
    FOLLOWING_PRODUCTS_ARE_UNAVAILABLE: "Following products are unavailable",
    FOLLOWING_PRODUCTS_HAVE_LESS_STOCK: "Following products have less stock",
    FOLLOWING_PRODUCTS_CANNOT_BE_SOLD: "Following products cannot be sold",
    FOLLOWING_PRODUCTS_OUT_OF_STOCK: "Following products are out of stock",
    FOLLOWING_PRODUCTS_BLACKLISTED: "Following products are blacklisted",
    FOLLOWING_FREE_PRODUCTS_OUT_OF_STOCK: "Following free products are out of stock",
    FOLLOWING_FREE_PRODUCTS_NOT_AVAILABLE: "Following free products not available",
    FOLLOWING_FREE_PRODUCTS_ARE_BLACKLISTED: "Following free products are blacklisted:",
    WE_ARE_UNAVLE_SELL_YOU_THE_FOLLOWING_PRODUCTS: "We are unable to sell you the following product(s):",
    ITEM_REMOVE_NO_ACCURATE_TRAINING: "This item has been removed from the cart because partner has no accurate training for this product",
    ITEM_REMOVED_CANNOT_BE_SOLD: "This item has been removed from the cart because it cannot be sold",
    ITEM_REMOVED_OUT_OF_STOCK: "This item has been removed from the cart because it is out of stock.",
    ITEM_REMOVED_UNAVAILABLE: "This item has been removed from the cart because it is unavailable.",
    ITEM_REMOVED_BLACKLISTED: "This item has been removed from the cart because it is blacklisted.",
    CHANGED_FROM: "Changed from",
    TO: "to",
    AMOUNT_TO_PAY: "Amount to pay",
    ADD_PAYMENT: "Add payment",
    BALANCE: "Balance",
    PHONE_NUMBER: "Phone number",
    PAYMENT_PROVIDER: "Payment Provider",
    NOTE: "NOTE",
    YOU_ARE_DOING_TEST_ORDER: "You are doing a TEST ORDER",
    ORDER_WILL_NOT_BE_PAID: "This order will not be paid and processed",
    TEST_ORDER_CREATED_SUCCESSFULLY: "Test Order Created Successfully",
    ORDER_CREATED_SUCCESSFULLY: "Order Created Successfully",
    ORDER_CREATED_SUCCESSFULLY_AND_ORDER_ID: "Your order has been created successfully and Payment should be done on delivery. Your order ID is",
    PROCESSING_PAYMENT: "Processing Payment",
    WAIT_PAYMENT_IS_PROCESSING: "Please wait your payment is being processed. It might take a few minutes",
    SENDING_MONEY: "SENDING MONEY",
    YOU_ARE_PAYING: "You are paying",
    ENTER_MOBILE_MONEY_PIN_TO_CONFIRM: "Please enter your Mobile Money PIN to confirm",
    THIS_IS_A_TEST_PAYMENT: "This is a TEST ORDER. No money will be deducted",
    CANCEL: "CANCEL",
    SEND: "SEND",
    PAYMENT_SUCCESSFUL: "Payment Successful",
    PAYMENT_UNSUCCESSFUL: "Payment Unsuccessful",
    PAYMENT_SUCCESSFUL_YOUR_ORDER_ID: "Your payment is successful and your order ID is",
    PAYMENT_UNSUCCESSFUL_BECAUSE: "Your payment is unsuccessful because",
    TRY_AGAIN: "Try Again",
    TRANSACTION_ID_IS: "Transaction ID is",
    DELIVERY_DATE_TXT: "Delivery between {deliveryStart} and {deliveryEnd}",
    PAYMENT_NOT_YET_PROCESSED: "Payment not yet processed",
    WOULD_YOU_LIKE_TO_TAKE_FEW_MINUTES_TO_PROCESS: "Would you like to keep waiting? It might still take a few more minutes",
    YES: "Yes",
    CHECK_STATUS: "Check status",
    CHECK_LATER: "Check later",
    THE_PHONE_NUMBER_IS_NEW: "The phone number {phoneNumber} is a new",
    PAYMENT_NUMBER: "payment number.",
    YOU_WANT_TO_USER_THIS_NUMBER_FOR_FUTURE_ORDERS: "You want to use this number for future order?",
    NO_THANKS: "No, thanks",
    SEARCH_FOR_NAME_OR_PHONE_NUMBER: "Search for name or phone number",
    ORDER_VALUE_TOO_LOW: "Order value too low",
    MINIMUM_ORDER_VALUE_IS: "Minimum order value is",
    CREDIT_OVERVIEW: "Credit Overview",
    OPEN_PAYMENTS_LOWER_CASE: "Open Payments",
    UPDATE_BALANCE_UPPER_CASE: "UPDATE BALANCE",
    BACK_TO_HOME_SCREEN: "Back to home screen",
    PAID_UPPER_CASE: "PAID",
    INVOICE_DATE: "Invoice Date",
    DUE_DATE: "Due Date",
    OPEN: "OPEN",
    PAID: "PAID",
    I_WANT_TO_PAY: "I want to pay",
    UPDATED_LOWER_CASE: "Updated",
    TOTAL_UPPER_CASE: "TOTAL",
    BALANCE_UPPER_CASE: "BALANCE",
    SALES_PERSON: "Salesperson",
    ORDER_NUMBER: "Order Nr",
    FILL_PAYMENT_AMOUNT: "Fill payment amount",
    PRODUCTS_SYNCED: "Products synced!",
    YOUR_ORDER_CREATED_SUCCESSFULLY_DELIVERY: "Your order has been created successfully and Payment should be done on delivery. Your order ID is",
    YOUR_ORDER_CREATED_SUCCESSFULLY: "Your order has been created successfully, and your order ID is",
    PLEASE_SELECT_MOMO_CARRIER: "Please select a mobile money carrier",
    RETURN_TO_HOME: "Return to Home",
    HAS_BEEN_DEDUCTED_FROM_BALANCE: "has been deducted from your outstanding balance",
    NO_DATA: "No Data"
  },
  [LOCALES.FRENCH]: {
    SEARCH: "Recherche",
    CREDIT_BALANCE: "SOLDE CRÉDITEUR",
    OPEN_PAYMENTS: "PAIEMENTS OUVERTS",
    UPDATED: "MIS À JOUR",
    ACCOUNT: "Compte",
    ALL_ORDERS: "Tous les ordres",
    LOGOUT: "Se déconnecter",
    LOADING: "Chargement",
    PAYMENT_SERVICE_LOADING: "Chargement du service de paiement",
    ALL: "Tout",
    IN_YOUR_CART: "Dans votre panier",
    CUSTOMER: "Client",
    TOTAL: "Totale",
    YOUR_CART_IS_EMPTY: "Votre panier est vide",
    YOU_CURRENTLY_HAVE_NO_ITEM_ADDED: "Vous n'avez actuellement aucun article ajouté à votre panier",
    GO_TO_PRODUCT_PAGE: "Aller à la page produit",
    FREE: "GRATUIT",
    PAY_WITH_CASH: "Payer en espèces",
    PAY_WITH_MOBILE_MONEY: "Payer avec de l'argent mobile",
    TEST_PAYMENT: "Tester le paiement",
    PLEASE_SELECT_PAYMENT_METHOD: "Veuillez choisir un moyen de paiement",
    PAY: "Payer",
    REFRESH_ALL_ORDERS: "Actualiser toutes les commandes",

    CONFIRMED: "Confirmé",
    TO_BE_CONFIRMED: "Être confirmé",
    LEFT_WAREHOUSE: "Entrepôt de gauche",
    NOT_DELIVERED: "Non livrés",
    CANCELLED: "Annulé",
    STATUS: "Statut",
    START_DATE: "Date de début",
    END_DATE: "Date de fin",
    NO_INTERNET_CONNECTION: "Pas de connexion Internet. Veuillez vous connecter à Internet avant de finaliser le paiement d'une commande",
    GO_BACK: "Retourner",
    OLD_PIN: "Ancien code NIP",
    ENTER_YOUR_NEW_PIN: "Entrez votre nouveau code NIP",
    CONFIRM_YOUR_NEW_PIN: "Confirmer le code NIP",
    CHANGE_PIN: "Changer le code NIP",
    ENTER_YOUR_OLD_PIN: "Entrez votre ancien code NIP",
    PRICES_CHANGED_FOR: "Les prix ont changé pour",
    FOLLOWING_PRODUCTS_ARE_UNAVAILABLE: "Les produits suivants ne sont pas disponibles",
    FOLLOWING_PRODUCTS_HAVE_LESS_STOCK: "Les produits suivants ne sont pas disponibles",
    FOLLOWING_PRODUCTS_CANNOT_BE_SOLD: "Les produits suivants ne peuvent pas être vendus",
    FOLLOWING_PRODUCTS_OUT_OF_STOCK: "Les produits suivants sont en rupture de stock",
    FOLLOWING_PRODUCTS_BLACKLISTED: "Les produits suivants sont sur liste noire",
    FOLLOWING_FREE_PRODUCTS_OUT_OF_STOCK: "Les produits gratuits suivants sont en rupture de stock",
    FOLLOWING_FREE_PRODUCTS_NOT_AVAILABLE: "Produits gratuits suivants non disponibles",
    FOLLOWING_FREE_PRODUCTS_ARE_BLACKLISTED: "Les produits gratuits suivants sont sur liste noire :",
    WE_ARE_UNAVLE_SELL_YOU_THE_FOLLOWING_PRODUCTS: "Nous ne sommes pas en mesure de vous vendre le(s) produit(s) suivant(s) :",
    ITEM_REMOVE_NO_ACCURATE_TRAINING: "Cet article a été supprimé du panier car le partenaire ne dispose d'aucune formation précise pour ce produit.",
    ITEM_REMOVED_CANNOT_BE_SOLD: "Cet article a été supprimé du panier car il ne peut pas être vendu",
    ITEM_REMOVED_OUT_OF_STOCK: "Cet article a été retiré du panier car il est en rupture de stock.",
    ITEM_REMOVED_UNAVAILABLE: "Cet article a été supprimé du panier car indisponible.",
    ITEM_REMOVED_BLACKLISTED: "Cet article a été supprimé du panier car il est sur liste noire.",
    CHANGED_FROM: "Changé de",
    TO: "à",
    AMOUNT_TO_PAY: "Montant à payer",
    ADD_PAYMENT: "Ajouter un paiement",
    BALANCE: "Équilibre",
    PHONE_NUMBER: "Numéro de téléphone",
    PAYMENT_PROVIDER: "Fournisseur de paiement",
    NOTE: "NOTE",
    YOU_ARE_DOING_TEST_ORDER: "Vous faites une COMMANDE TEST",
    ORDER_WILL_NOT_BE_PAID: "Cette commande ne sera ni payée ni traitée",
    TEST_ORDER_CREATED_SUCCESSFULLY: "Commande test créée avec succès",
    ORDER_CREATED_SUCCESSFULLY: "Commande créée avec succès",
    ORDER_CREATED_SUCCESSFULLY_AND_ORDER_ID: "Votre commande a été créée avec succès et le paiement doit être effectué à la livraison. Votre numéro de commande est",
    PROCESSING_PAYMENT: "Traitement du paiement",
    WAIT_PAYMENT_IS_PROCESSING: "Veuillez patienter, votre paiement est en cours de traitement. Cela peut prendre quelques minutes",
    SENDING_MONEY: "ENVOYER DE L'ARGENT",
    YOU_ARE_PAYING: "Vous payez",
    ENTER_MOBILE_MONEY_PIN_TO_CONFIRM: "Veuillez saisir votre code PIN Mobile Money pour confirmer",
    THIS_IS_A_TEST_PAYMENT: "Ceci est une COMMANDE TEST. Aucun argent ne sera déduit",
    CANCEL: "ANNULER",
    SEND: "ENVOYER",
    PAYMENT_SUCCESSFUL: "Paiement réussi",
    PAYMENT_UNSUCCESSFUL: "Paiement échoué",
    PAYMENT_SUCCESSFUL_YOUR_ORDER_ID: "Votre paiement est réussi et votre numéro de commande est",
    PAYMENT_UNSUCCESSFUL_BECAUSE: "Votre paiement échoue car",
    TRY_AGAIN: "Essayer à nouveau",
    TRANSACTION_ID_IS: "L’ID de transaction est",
    DELIVERY_DATE_TXT: "Livraison entre le {deliveryStart} et le {deliveryEnd}",
    PAYMENT_NOT_YET_PROCESSED: "Paiement pas encore traité",
    WOULD_YOU_LIKE_TO_TAKE_FEW_MINUTES_TO_PROCESS: "Souhaitez-vous continuer à attendre ? Cela pourrait encore prendre quelques minutes",
    YES: "Oui",
    CHECK_STATUS: "Vérifier l'état",
    CHECK_LATER: "Vérifiez plus tard",
    THE_PHONE_NUMBER_IS_NEW: "Le numéro de téléphone {phoneNumber} est un nouveau",
    PAYMENT_NUMBER: "numéro de paiement",
    YOU_WANT_TO_USER_THIS_NUMBER_FOR_FUTURE_ORDERS: "Vous souhaitez utiliser ce numéro pour une prochaine commande ?",
    NO_THANKS: "Non merci",
    SEARCH_FOR_NAME_OR_PHONE_NUMBER: "Rechercher un nom ou un numéro de téléphone",
    ORDER_VALUE_TOO_LOW: "Valeur de la commande trop faible",
    MINIMUM_ORDER_VALUE_IS: "La valeur minimale de la commande est de",
    CREDIT_OVERVIEW: "Aperçu du crédit",
    OPEN_PAYMENTS_LOWER_CASE: "Paiements ouverts",
    UPDATE_BALANCE_UPPER_CASE: "MISE À JOUR DU SOLDE",
    UPDATE: "Mis à jour",
    BACK_TO_HOME_SCREEN: "Retour à l'écran d'accueil",
    PAID_UPPER_CASE: "PAYÉ",
    INVOICE_DATE: "Date de facture",
    DUE_DATE: "Date d'échéance",
    OPEN: "OUVRIR",
    PAID: "PAYÉ",
    I_WANT_TO_PAY: "je veux payer",
    UPDATED_LOWER_CASE: "Mis à jour",
    TOTAL_UPPER_CASE: "TOTALE",
    BALANCE_UPPER_CASE: "ÉQUILIBRE",
    SALES_PERSON: "Vendeuse",
    ORDER_NUMBER: "Numéro de commande",
    FILL_PAYMENT_AMOUNT: "Remplissez le montant du paiement",
    PRODUCTS_SYNCED: "Produits synchronisés !",
    YOUR_ORDER_CREATED_SUCCESSFULLY_DELIVERY: "Votre commande a été créée avec succès et le paiement doit être effectué à la livraison. Votre numéro de commande est",
    YOUR_ORDER_CREATED_SUCCESSFULLY: "Votre commande a été créée avec succès et votre numéro de commande est",
    PLEASE_SELECT_MOMO_CARRIER: "Veuillez sélectionner un opérateur d'argent mobile",
    RETURN_TO_HOME: "Retourner à la maison",
    HAS_BEEN_DEDUCTED_FROM_BALANCE: "a été déduit de votre solde impayé",
    NO_DATA: "Pas de données" 
  }
}