import { apiRequest } from "../apiRequest";
import { serializeQueryParamsOnNull } from "../../../util/helpers/commonHelper"

export const fetchUsers = (query) => {
	let search_term = query.search_term || null;
	let company_id = query.company_id || null;
	let status = query?.status;
	let is_active = query?.is_active;
	let page = query && query.page;
	let limit = 50;
	let district_id = query.district_id || null
	let cluster_id = query.cluster_id || null

	const qBody = {
		search: search_term,
		company_id,
		is_active: status ? is_active : undefined,
		city: district_id,
		street: cluster_id,
		page,
		limit
	}

	const queryStr = serializeQueryParamsOnNull(qBody)

	return apiRequest(
		"GET",
		`/user/ches${queryStr}`);
}

export const updateUser = (id, payload) => {
  return apiRequest(
    "PATCH",
    `/user/ches/${id}`,
    payload
  )
}