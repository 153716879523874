import { createTypes } from "reduxsauce";

export default createTypes(
	`
  GET_PHONE_NUMBER_DETAILS
  GET_PHONE_NUMBER_DETAILS_SUCCESS
  GET_PHONE_NUMBER_DETAILS_FAILURE
  RESET_PHONE_NUMBER_DETAILS

`,
	{}
);
