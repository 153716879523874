import moment from "moment"

export function serializeQueryParams( obj ) {
  return '?' + Object.keys(obj).reduce(function(a, k){
    if(obj[k]) {
      a.push(k + '=' + encodeURIComponent(obj[k]));
    }
    return a;
  }, []).join('&');
}

export function serializeQueryParamsOnNull( obj ) {
  return '?' + Object.keys(obj).reduce(function(a, k){
    if(obj[k] !== null && obj[k] !== undefined && obj[k] !== "") {
      a.push(k + '=' + encodeURIComponent(obj[k]));
    }
    return a;
  }, []).join('&');
}

export function checkExpiryDateTime(date) {
  const now = moment()
  const expiryDate = moment(date)
  const result = now < expiryDate
  return result
}


export function getTimerCountdown(countDown) {
  // calculate time left
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  return [days, hours, minutes, seconds];
};

export function padZero(n, width, z) {
  z = z || '0';
  n = n + '';
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

export function excludeFromMaintenanceScreen(phoneNumber){
  if(!phoneNumber) {
    return false 
  }
  let numbers = process.env.REACT_APP_EXCLUDE_LIST || "+256780804444,+256702862968,+256702527294,+256772622232,+254703566737,+256777707675,+256777978092,+256778166690,+256702795399"
  // let numbers = [
  //   '+256780804444',
  //   '+256702862968',
  //   '+256702527294',
  //   '+256772622232',
  //   '+254703566737',
  //   '+256777707675',
  //   '+256777978092',
  //   '+256778166690',
  //   '+256702795398'
  // ]
  let arr = numbers.split(",")
  return arr.includes(phoneNumber)
}

export function getUserFromLocalStorage() {
  if(localStorage.getItem("he-che-user")) {
    return JSON.parse(localStorage.getItem("he-che-user") )
  } else {
    return null
  }
}

export function refreshProductLocalCache() {
  if ('caches' in window) {
    caches.delete('endpoints-cache')
    //caches.delete('images-cache')
    // caches.keys().then(names => {
    //   names.forEach(name => {
    //     caches.delete(name)
    //   })
    // })
  } else {
    console.log('Browser does not support cache storage!')
  }
}


export function refreshLocalCache() {
  if ('caches' in window) {
    caches.delete('endpoints-cache')
    caches.delete('images-cache')
    caches.keys().then(names => {
      names.forEach(name => {
        caches.delete(name)
      })
    })
  } else {
    console.log('Browser does not support cache storage!')
  }
}


export function cleanCartProductsObject(order) {
  let modifiedOrder = order
    .filter((item) => !item.out_of_stock)
    .filter((item) => !item.blacklisted)
    .filter(item => item.sale_ok)
    .filter(item => !item.unaccepted_products)
    .reduce((acc, cur) => {
      let newObj = {
        product_id: cur.id,
        product_uom_qty: cur.quantity,
        last_price: cur.lst_price,
        price_list_rule: cur.price_list_rule
      };
      return (acc = [...acc, newObj]);
    }, []);
  return modifiedOrder;
}

export function roundUpFloat(num, decimalPlaces = 0) {
  let number = Math.ceil(num * 100) / 100;
  return number.toFixed(decimalPlaces)
}